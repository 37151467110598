import React, { Suspense, createContext, lazy, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FormProvider } from './components/forms/addVideo/FormContext';
import theme from './theme';
import PrivateRoute from './components/router/PrivateRoute';
import { Book } from './types/ebooks/ebooks';
import EbookLayout from './layouts/ebooks/EbookLayout';
import VideoLayout from './layouts/videos/VideoLayout';
import BookDetails from './components/ebooks/BookDetails';
import Home from './pages/ebooks/Home';
import Reader from './pages/ebooks/Reader';
import VideoManager from './components/uploadVideo/VideoManager';
import AppBar from './layouts/GeneralAppBar/AppBar';
import VideoClick from './pages/VideoClick'; // Adjust the path to your project structure
import Guideline from './pages/GeneralAppBar/GuidelinePage'; // Imported from origin/final_asmae
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

// Import additional components for new pages

// Lazy load components
const SignIn = lazy(() => import('./pages/SignIn'));
const SignUp = lazy(() => import('./pages/SignUp'));
const TwoFactor = lazy(() => import('./pages/TwoFactor'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const HomePage = lazy(() => import('./pages/GeneralAppBar/HomePage'));
const PartnersPage = lazy(() => import('./pages/GeneralAppBar/PartnersPage')); // Adjust path
const TeamPage = lazy(() => import('./pages/GeneralAppBar/TeamPage')); // Adjust path
const ContactPage = lazy(() => import('./pages/contact/Contact')); // Adjust path
const Map = lazy(() => import('./pages/InteractiveMap'));
const ConsolidatedPage = lazy(() => import('./pages/GeneralAppBar/ConsolidatedPage')); // Adjust path

// Create Language Context
export const LanguageContext: React.Context<any> = createContext(null);

const App: React.FC = () => {
  const [toReadBooks, setToReadBooks] = useState<Book[]>([]);

  const addToRead = (book: Book) => {
    setToReadBooks([...toReadBooks, book]);
  };

  const [language, setLanguage] = useState('en');

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <FormProvider>
          <Router>
            {/* Uncomment and configure the AppBar as needed */}
            {/* <AppBar
              toggleSidebar={() => {}}
              setSearchQuery={() => {}}
              searchQuery=""
            /> */}
            <Suspense
              fallback={
                <div className="flex justify-center items-center h-screen">
                  <div className="w-16 h-16 border-4 border-blue-500 border-solid border-t-transparent rounded-full animate-spin"></div>
                </div>
              }
            >
              <Routes>
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />

                <Route path="/two-factor" element={<TwoFactor />} />
                <Route path="/" element={<Navigate to="/home" />} />

                {/* New routes for home, about us, heritage, etc. */}
                <Route path="/home" element={<LandingPage />} /> {/* Mapped to LandingPage */}
                <Route path="/guideline" element={<Guideline />} /> {/* Added GuidelinePage */}
                <Route path="/partners" element={<PartnersPage />} />
                <Route path="/team" element={<TeamPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/aboutUs" element={<ConsolidatedPage />} />
                <Route path="/map" element={<Map />} />

                {/* Existing videos and ebooks routes */}
                <Route
                  path="/videos/*"
                  element={
                    // <PrivateRoute
                    //   element={
                        <VideoLayout>
                          <Routes>
                            <Route path="your-videos/*" element={<VideoManager />} />
                            <Route path="/" element={<Navigate to="/videos/your-videos" />} />
                          </Routes>
                        </VideoLayout>
                    //   }
                    // />
                  }
                />
                <Route path="/video-details/:id" element={<VideoClick />} />
                <Route
                  path="/ebooks/*"
                  element={
                    // <PrivateRoute
                    //   element={
                        <EbookLayout toReadBooks={toReadBooks} addToRead={addToRead} />
                    //   }
                    // />
                  }
                >
                  <Route path="home" element={<Home addToRead={addToRead} />} />
                  <Route path=":id" element={<BookDetails />} />
                  <Route path="read/:id" element={<Reader />} />
                </Route>
              </Routes>
            </Suspense>
          </Router>
        </FormProvider>
        <ToastContainer />
      </ThemeProvider>
    </LanguageContext.Provider>
  );
};

export default App;
