import React, { useState, useEffect } from 'react';
import { Segment, SegmentModalProps, SegmentWithIndex } from '../../types/forms/video';
import { languages } from '../mock/languages'; // Adjust the import path as necessary
import { fetchLanguages } from '../../services/language';

const SegmentModal: React.FC<SegmentModalProps & { segments: Segment[], setSegmentError: React.Dispatch<React.SetStateAction<string | null>> }> = ({ onClose, onSave, segment, segments, setSegmentError }) => {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState(''); // State for description
  const [transcripts, setTranscripts] = useState([{ language: '', transcript: '' }]);
  const [customLanguages, setCustomLanguages] = useState<boolean[]>([]);
  const [index, setIndex] = useState<number | undefined>(undefined);
  const [languageOptions, setLanguageOptions] = useState<{ value: string; label: string }[]>([]);
  const [loading, setLoading] = useState(true);
  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');
  const [overlapError, setOverlapError] = useState('');

  useEffect(() => {
    const getLanguages = async () => {
      try {
        const data = await fetchLanguages();
        console.log("lang",data)
        const options = data.map((lang: { value: number; label: string }) => ({
          value: lang.value,
          label: lang.label
        }));
        
        setLanguageOptions(options);
      } catch (error) {
        console.error('Failed to load languages:', error);
      } finally {
        setLoading(false);
      }
    };

    getLanguages();
  }, []);
  useEffect(() => {
    if (segment) {
      console.log("segs",segments,"seg wa7d",segment)
      setStartTime(segment.startTime);
      setEndTime(segment.endTime);
      setTitle(segment.title);
      setDescription(segment.description); // Set description
      setTranscripts(segment.transcripts);
      setIndex(segments.findIndex(seg => seg === segment)); // Determine index of the segment
    }
  }, [segment, segments]);

  const handleSave = () => {
    const timeToSeconds = (time: string): number => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    };
  
    if (!startTime || !endTime) {
      if (!startTime) setStartTimeError('Start time is required.');
      if (!endTime) setEndTimeError('End time is required.');
      return;
    }
  
    console.log('Raw Start Time:', startTime);
    console.log('Raw End Time:', endTime);
  
    const startInSeconds = timeToSeconds(startTime);
    const endInSeconds = timeToSeconds(endTime);
  
    console.log('Start in Seconds:', startInSeconds);
    console.log('End in Seconds:', endInSeconds);
  
    if (endInSeconds <= startInSeconds) {
      setEndTimeError('End time must be greater than start time.');
      return;
    }
  
    const isOverlap = segments.some((seg, i) => {
      if (index !== undefined && i === index) return false;
  
      const segStartInSeconds = timeToSeconds(seg.startTime);
      const segEndInSeconds = timeToSeconds(seg.endTime);
  
      return startInSeconds < segEndInSeconds && endInSeconds > segStartInSeconds;
    });
  
    if (isOverlap) {
      setOverlapError('Segment overlaps with an existing segment.');
      return;
    }
  
    const newSegment: SegmentWithIndex = { startTime, endTime, title, description, transcripts, index };
    onSave(newSegment);
    setSegmentError(null); 
    setOverlapError(''); 
    setStartTimeError(''); 
    setEndTimeError(''); 
    onClose(); 
  };
  
  
  const handleTranscriptChange = (index: number, field: string, value: string) => {
    const newTranscripts = [...transcripts];
    newTranscripts[index] = { ...newTranscripts[index], [field]: value };
    setTranscripts(newTranscripts);
  };

  const addTranscript = () => {
    setTranscripts([...transcripts, { language: '', transcript: '' }]);
    setCustomLanguages([...customLanguages, false]);
  };

  const addTranscriptAtIndex = (index: number) => {
    const newTranscripts = [
      ...transcripts.slice(0, index + 1),
      { language: '', transcript: '' },
      ...transcripts.slice(index + 1)
    ];
    const newCustomLanguages = [
      ...customLanguages.slice(0, index + 1),
      false,
      ...customLanguages.slice(index + 1)
    ];
    setTranscripts(newTranscripts);
    setCustomLanguages(newCustomLanguages);
  };

  const removeTranscript = (index: number) => {
    setTranscripts(transcripts.filter((_, i) => i !== index));
    setCustomLanguages(customLanguages.filter((_, i) => i !== index));
  };

  const handleLanguageChange = (index: number, value: string) => {
    const newCustomLanguages = [...customLanguages];
    if (value === 'Other') {
      newCustomLanguages[index] = true;
      handleTranscriptChange(index, 'language', '');
    } else {
      newCustomLanguages[index] = false;
      handleTranscriptChange(index, 'language', value);
    }
    setCustomLanguages(newCustomLanguages);
  };

  console.log("transcripts",transcripts) 

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg max-h-screen overflow-y-auto">
        <h2 className="text-2xl font-bold mb-4">{segment ? 'Edit Segment' : 'Add Segment'}</h2>
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">Start Time</label>
          <input
  type="text"
  value={startTime}
  placeholder="HH:MM:SS"
  pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
  onChange={(e) => {
    setStartTime(e.target.value);
    setStartTimeError(''); 
  }}
  className={`w-full p-3 border ${
    startTimeError ? 'border-red-500' : 'border-gray-300'
  } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
  title="Please enter a valid time in HH:MM:SS format"
/>{startTimeError && <p className="text-red-500 text-sm">{startTimeError}</p>}


        </div>
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">End Time</label>
      
          <input
  type="text"
  value={endTime}
  placeholder="HH:MM:SS"
  pattern="[0-9]{2}:[0-9]{2}:[0-9]{2}"
  onChange={(e) => {
    setEndTime(e.target.value);
    setEndTimeError(''); 
  }}
  className={`w-full p-3 border ${
    endTimeError ? 'border-red-500' : 'border-gray-300'
  } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
title="Please enter a valid time in HH:MM:SS format"
/>
{endTimeError && <p className="text-red-500 text-sm">{endTimeError}</p>}


        </div>
        {overlapError && <p className="text-red-500 text-sm">{overlapError}</p>}

        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={3}
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-semibold mb-2">Transcripts</label>
          {transcripts.map((transcript, index) => (
            <div key={index} className="mb-2 flex items-center">
              <div className="flex-grow">
              <select
                value={transcript.language}
                onChange={(e) => handleTranscriptChange(index, 'language', e.target.value)}
                className="w-full p-2 mb-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="" disabled>Select Language</option>
                {languageOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}

                </select>
                
                <input
                  type="text"
                  placeholder="Transcript"
                  value={transcript.transcript}
                  onChange={(e) => handleTranscriptChange(index, 'transcript', e.target.value)}
                  className="w-full p-2 mb-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  onClick={() => addTranscriptAtIndex(index)}
                  className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition"
                >
                  +
                </button>
                <button
                  type="button"
                  onClick={() => removeTranscript(index)}
                  className="text-red-500 hover:underline"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={addTranscript}
            className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
          >
            Add Transcript
          </button>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onClose}
            className="p-3 bg-gray-300 text-gray-800 rounded-lg mr-2 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SegmentModal;
