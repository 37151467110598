// src/utils/highlightHtml.js

import DOMPurify from 'dompurify';

/**
 * Highlights all occurrences of the searchTerm in the htmlString.
 * @param {string} htmlString - The original HTML string.
 * @param {string} searchTerm - The term to highlight.
 * @returns {string} - The sanitized HTML string with highlighted terms.
 */
// src/utils/highlightHtml.js
// src/utils/highlightHtml.js

let matchIndexCounter = 0; // We'll reset this from outside if needed

export function highlightHtml(htmlContent, searchTerm) {
  if (!htmlContent || !searchTerm) return htmlContent;

  // Escape special regex chars
  const escaped = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  // Case-insensitive
  const regex = new RegExp(`(${escaped})`, "gi");

  // We'll wrap each match with a <span> that has a data attribute
  const highlighted = htmlContent.replace(regex, () => {
    const span = `<span class="highlighted-match" 
          data-match-index="${matchIndexCounter}"
          style="background-color: yellow;">${searchTerm}</span>`;
    matchIndexCounter++;
    return span;
  });

  return highlighted;
}

// Optional helper to reset matchIndexCounter to 0 each time we do a fresh highlight.
export function resetMatchIndexCounter() {
  matchIndexCounter = 0;
}


/**
 * Escapes special characters in a string for use in a regular expression.
 * @param {string} string - The string to escape.
 * @returns {string} - The escaped string.
 */
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
