import React, { useState, FormEvent } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { requestPasswordReset } from '../services/authService';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const result = await requestPasswordReset(email);
    if (result.success) {
      toast.success(result.message);
      navigate('/signin');
    } else {
      toast.error(result.message);
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Forgot Password | Your App</title>
        <meta name="description" content="Reset your account password." />
      </Helmet>
      <div className="h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-2xl font-semibold mb-4 text-center text-black-600">Forgot Password</h2>
          <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
            <div>
              <label htmlFor="email" className="block mb-1 text-sm font-semibold text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full py-2 px-4 border border-gray-300 rounded-lg text-sm outline-none focus:border-green-500"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                onClick={() => navigate('/signin')}
                className="py-2 px-4 bg-gray-400 text-white rounded-lg text-sm font-semibold hover:bg-gray-500 transition duration-200"
              >
                Back
              </button>
              <button
                type="submit"
                className="py-2 px-4 bg-green-500 text-white rounded-lg text-sm font-semibold hover:bg-green-600 transition duration-200"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ForgotPassword;
