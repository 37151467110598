import React, { useEffect, useRef, useState, useMemo } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Services
import { fetchVideoDataByID, fetchChannelDataByID } from "../services/generalVideoServices";

// Types
import { Video, Transcript } from "../types/domain";

// Components
import PageNotFound from "./PageNotFound";
import VideoSection from "../components/VideoSection";
import { TranscriptWidget } from "../components/TranscriptWidget";
import CommentSection from "../components/CommentSection";

export const Watch: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const videoId = parseInt(id || "0", 10);

  const [videoData, setVideoData] = useState<Video | null>(null);
  const [channelData, setChannelData] = useState<any>(null);
  const playerRef = useRef<ReactPlayer | null>(null);

  // Check authentication status on mount
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      toast.warning("You are not logged in. Some features may not be available.");
    }
  }, []);

  // Fetch video data
  useEffect(() => {
    (async () => {
      try {
        const data = await fetchVideoDataByID(id);
        setVideoData(data);
      } catch (error) {
        console.error("Failed to fetch video data:", error);
      }
    })();
  }, [id]);

  // Fetch channel data if video data is available
  useEffect(() => {
    (async () => {
      if (videoData?.media_details?.channelID) {
        try {
          const data = await fetchChannelDataByID(videoData.media_details.channelID);
          setChannelData(data);
        } catch (error) {
          console.error("Failed to fetch channel data:", error);
        }
      }
    })();
  }, [videoData]);

  // Always call this hook, even if videoData is null
  // (just return an empty array as a fallback)
  const filteredTranscripts: Transcript[] = useMemo(() => {
    if (!videoData?.transcripts) return [];
    const onlyNullSegment = videoData.transcripts.filter(
      (t) => t.videoSegmentID === null
    );

    // Deduplicate by transcriptionLanguage
    const deduped: Transcript[] = [];
    for (const t of onlyNullSegment) {
      const alreadyExists = deduped.some(
        (x) => x.transcriptionLanguage === t.transcriptionLanguage
        
      );
      if (!alreadyExists) {
        deduped.push(t);
      }
    }
    return deduped;
  }, [videoData]);

  // Now do the early return AFTER all Hooks
  if (!videoData) {
    return <PageNotFound />;
  }

  return (
    <div className="flex flex-col gap-4">
      <VideoSection
        videoData={videoData}
        playerRef={playerRef}
        channelData={channelData}
      />
      <TranscriptWidget
        videoId={videoId}
        playerRef={playerRef}
        transcripts={filteredTranscripts}
      />
      <CommentSection videoId={videoId} />
      <ToastContainer />
    </div>
  );
};
