import React from 'react';

interface SummaryCardProps {
  title: string;
  value: number;
  increment: number;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ title, value, increment }) => {
  return (
    <div className="bg-white shadow-lg rounded-2xl p-4 flex flex-col justify-between w-96 h-28 m-4">  {/* Adjusted width to w-96 */}
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-gray-900 text-xl font-semibold">{title}</h3>
          <p className="text-sm text-gray-500">Weekly Statistic</p>
        </div>
        <div className="text-black-500 text-lg font-bold">{increment}</div>
      </div>
      <div className="relative mt-2">
        <div className="overflow-hidden h-2 text-xs flex rounded-full bg-gray-300"> {/* Made the progress bar fully rounded */}
          <div style={{ width: `${(value / 100000) * 100}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-pink-500 rounded-full"></div> {/* Made the pink bar fully rounded */}
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
