// src/components/Checks.tsx
import React, { useState, useEffect } from 'react';
import { checkProps } from '../../../types/forms/video';
import Modal from './IntervieweeModal'; // Adjust the import path as necessary
import { fetchLanguages } from '../../../services/language';
import LocationPicker from '../../LocationPicker'; // Adjust the import path as necessary

const Checks: React.FC<checkProps> = ({
  formData,
  handleChange,
  handleBack,
  handleSubmit,
  handleSavePerson,
}) => {
  const [showModal, setShowModal] = useState<{
    type: 'interviewee' | 'interviewer' | null;
    index: number | null;
  }>({ type: null, index: null });

  const openModal = (type: 'interviewee' | 'interviewer', index: number | null = null) => {
    setShowModal({ type, index });
  };

  const closeModal = () => {
    setShowModal({ type: null, index: null });
  };

  const [languageOptions, setLanguageOptions] = useState<{ value: string; label: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLanguages = async () => {
      try {
        const data = await fetchLanguages();
        console.log("Languages fetched:", data);
        const options = data.map((lang: { value: number; label: string }) => ({
          value: lang.value.toString(), // Ensure value is a string
          label: lang.label,
        }));

        setLanguageOptions(options);
      } catch (error) {
        console.error('Failed to load languages:', error);
      } finally {
        setLoading(false);
      }
    };

    getLanguages();
  }, []);

  const savePerson = (
    firstName: string,
    lastName: string,
    picture?: File,
    phoneNumber?: string,
    email?: string
  ) => {
    if (showModal.type !== null) {
      handleSavePerson(showModal.type, firstName, lastName, picture, phoneNumber, email, showModal.index ?? undefined);
    }
    closeModal();
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Handle text inputs (like city_name)
    handleChange(e);
  };

  const handleRemovePerson = (type: 'interviewee' | 'interviewer', index: number) => {
    const updatedList =
      type === 'interviewee'
        ? formData.interviewees.filter((_, i) => i !== index)
        : formData.interviewers.filter((_, i) => i !== index);

    const event = {
      target: { name: type === 'interviewee' ? 'interviewees' : 'interviewers', value: updatedList },
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    handleChange(event);
  };

  const handleEditPerson = (type: 'interviewee' | 'interviewer', index: number) => {
    openModal(type, index);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day for accurate comparison

    if (selectedDate > currentDate) {
      alert('Interview date cannot be in the future.');
      return;
    }

    handleChange(e);
  };

  // Handler to add a new transcript
  const addTranscript = () => {
    const newTranscript = { transcription: '', transcriptionLanguage: '' };
    const updatedTranscripts = [...formData.transcripts, newTranscript];
    handleChange({
      target: { name: 'transcripts', value: updatedTranscripts },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  // Handler to remove a transcript by index
  const removeTranscript = (index: number) => {
    const updatedTranscripts = formData.transcripts.filter((_, i) => i !== index);
    handleChange({
      target: { name: 'transcripts', value: updatedTranscripts },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  // Handler to update a specific transcript field
  const handleTranscriptChange = (
    index: number,
    field: 'transcription' | 'transcriptionLanguage',
    value: string
  ) => {
    const updatedTranscripts = formData.transcripts.map((transcript, i) =>
      i === index ? { ...transcript, [field]: value } : transcript
    );
    handleChange({
      target: { name: 'transcripts', value: updatedTranscripts },
    } as unknown as React.ChangeEvent<HTMLInputElement>);
  };

  console.log("Acknowledgment:", formData.acknowledgment);

  // **New States for Location Selection**
  const [position, setPosition] = useState<{ lat: number; lng: number } | null>(null);
  const [cityName, setCityName] = useState<string>('');

  // **Effect for Reverse Geocoding (Optional)**
  useEffect(() => {
    const fetchCityName = async () => {
      if (position) {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.lat}&lon=${position.lng}`
          );
          const data = await response.json();
          if (data.address && data.address.city) {
            setCityName(data.address.city);
            handleChange({
              target: {
                name: 'location.city_name',
                value: data.address.city,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          } else if (data.address && data.address.town) {
            setCityName(data.address.town);
            handleChange({
              target: {
                name: 'location.city_name',
                value: data.address.town,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          } else {
            setCityName('');
          }
        } catch (error) {
          console.error('Error fetching city name:', error);
        }
      }
    };

    fetchCityName();
  }, [position, handleChange]);

  // **Effect to Set Latitude and Longitude in formData**
  useEffect(() => {
    if (position) {
      handleChange({
        target: {
          name: 'location.latitude',
          value: position.lat,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
      handleChange({
        target: {
          name: 'location.longitude',
          value: position.lng,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    } else {
      handleChange({
        target: {
          name: 'location.latitude',
          value: null,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
      handleChange({
        target: {
          name: 'location.longitude',
          value: null,
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  }, [position, handleChange]);

  return (
    <div className="space-y-6 p-6 max-w-3xl mx-auto">
      {/* **Location Selection Section** */}
      <div className="relative">
        <label className="block text-lg font-medium text-gray-700 mb-2">Select Location on Map</label>
        <LocationPicker position={position} setPosition={setPosition} />

        {position && (
          <div className="mt-4">
            <p>
              <strong>Selected Position:</strong> Latitude {position.lat.toFixed(4)}, Longitude {position.lng.toFixed(4)}
            </p>
            {cityName && <p><strong>City Name:</strong> {cityName}</p>}
          </div>
        )}
      </div>

      {/* **Existing Location Fields** */}
      <div>
        <label className="block text-lg font-medium text-gray-700">City Name</label>
        <input
          type="text"
          name="location.city_name"
          value={formData.location.city_name || cityName}
          onChange={(e) => {
            handleChange(e);
            setCityName(e.target.value);
          }}
          placeholder="Enter the city name"
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
      </div>

      {/* **Monument Section (Removed as per your update)** */}
      {/* If you plan to re-add monument-related fields in the future, you can uncomment and modify the section below */}
      {/* 
      <div>
        <label className="block text-lg font-medium text-gray-700">Monument Name</label>
        <input
          type="text"
          name="location.monument_name"
          value={formData.location.monument_name}
          onChange={handleLocationChange}
          placeholder="Enter the monument name"
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
      </div>
      */}

      {/* **Acknowledgment Section** */}
      <div className="relative">
        <label htmlFor="acknowledgment" className="block text-lg font-medium text-gray-700">
          Acknowledgment
        </label>
        <input
          type="text"
          name="acknowledgment"
          id="acknowledgment"
          value={formData.acknowledgment}
          onChange={handleChange}
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
      </div>

      {/* **Topics Section** */}
      <div>
        <label htmlFor="topics" className="block text-lg font-medium text-gray-700">Topics</label>
        <input
          type="text"
          name="topics"
          id="topics"
          placeholder="Enter topics separated by commas (e.g. Finance,AI,Healthcare)"
          value={formData.topics}
          onChange={handleChange}
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
      </div>

      {/* **Important People Section** */}
      <div className="relative">
        <label htmlFor="important_persons" className="block text-lg font-medium text-gray-700">
          Important People
        </label>
        <input
          type="text"
          name="important_persons"
          id="important_persons"
          value={formData.important_persons}
          onChange={handleChange}
          placeholder="Enter important persons separated by commas"
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
      </div>

      {/* **Transcripts Section** */}
      <div className="relative">
        <label className="block text-lg font-medium text-gray-700">Transcripts</label>
        {formData.transcripts.map((transcript, index) => (
          <div key={index} className="mt-4 p-4 border rounded-md">
            <div className="flex justify-between items-center">
              <h4 className="text-md font-medium text-gray-600">Transcript {index + 1}</h4>
              <button
                type="button"
                onClick={() => removeTranscript(index)}
                className="text-red-600 hover:underline"
              >
                Remove
              </button>
            </div>
            <div className="mt-2">
              <label className="block text-sm font-medium text-gray-700">Transcript Language</label>
              <select
                name={`transcripts.${index}.transcriptionLanguage`}
                value={transcript.transcriptionLanguage}
                onChange={(e) => handleTranscriptChange(index, 'transcriptionLanguage', e.target.value)}
                className="mt-1 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
              >
                <option value="" disabled>Select a language</option>
                {languageOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Transcript</label>
              <textarea
                name={`transcripts.${index}.transcription`}
                value={transcript.transcription}
                onChange={(e) => handleTranscriptChange(index, 'transcription', e.target.value)}
                placeholder="Enter the transcription"
                className="mt-1 block w-full h-24 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
              />
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={addTranscript}
          className="mt-4 py-2 px-4 bg-purple-600 text-white rounded-md hover:bg-purple-700"
        >
          Add Transcript
        </button>
      </div>

      {/* **Buttons to Add Interviewees and Interviewers** */}
      <div className="flex space-x-4">
        <button
          type="button"
          onClick={() => openModal('interviewee')}
          className="mt-4 py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Add Interviewee
        </button>
        <button
          type="button"
          onClick={() => openModal('interviewer')}
          className="mt-4 py-2 px-4 bg-green-600 text-white rounded-md hover:bg-green-700"
        >
          Add Interviewer
        </button>
      </div>

      {/* **Modal for Adding or Editing Interviewee or Interviewer** */}
      {showModal.type && (
        <Modal
          type={showModal.type}
          onClose={closeModal}
          onSave={savePerson}
          initialData={
            showModal.index !== null
              ? showModal.type === 'interviewee'
                ? formData.interviewees[showModal.index]
                : formData.interviewers[showModal.index]
              : undefined
          }
        />
      )}

      {/* **Display Interviewees** */}
      <div>
        <h3 className="text-lg font-medium text-gray-700">Interviewees</h3>
        {formData.interviewees.length > 0 ? (
          <ul className="space-y-2">
            {formData.interviewees.map((person, index) => (
              <li key={index} className="flex justify-between items-center p-2 border rounded-md">
                <div>
                  <strong>{person.firstName} {person.lastName}</strong>
                  {person.phoneNumber && <p>Phone: {person.phoneNumber}</p>}
                </div>
                <div className="space-x-2">
                  <button
                    onClick={() => handleEditPerson('interviewee', index)}
                    className="text-blue-600 hover:underline"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleRemovePerson('interviewee', index)}
                    className="text-red-600 hover:underline"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No interviewees added yet.</p>
        )}
      </div>

      {/* **Display Interviewers** */}
      <div>
        <h3 className="text-lg font-medium text-gray-700">Interviewers</h3>
        {formData.interviewers.length > 0 ? (
          <ul className="space-y-2">
            {formData.interviewers.map((person, index) => (
              <li key={index} className="flex justify-between items-center p-2 border rounded-md">
                <div>
                  <strong>{person.firstName} {person.lastName}</strong>
                  {person.phoneNumber && <p>Phone: {person.phoneNumber}</p>}
                </div>
                <div className="space-x-2">
                  <button
                    onClick={() => handleEditPerson('interviewer', index)}
                    className="text-blue-600 hover:underline"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleRemovePerson('interviewer', index)}
                    className="text-red-600 hover:underline"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No interviewers added yet.</p>
        )}
      </div>

      {/* **Form Submission and Navigation Buttons** */}
      <div className="flex justify-between mt-4">
        <button onClick={handleBack} className="py-2 px-4 bg-gray-600 text-white rounded-md hover:bg-gray-700">
          Back
        </button>
        <button onClick={handleSubmit} className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700">
          Submit
        </button>
      </div>
    </div>
  );
};

export default Checks;
