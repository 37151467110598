import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchQuizByEbookId, submitQuizAnswers } from '../../services/ebooks';
import { ChevronLeft, ChevronRight, Check, X } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import ErrorBoundary from './ErrorBoundary'; 
interface Question {
  id: number;
  question_text: string;
  type: string;
  options: string[] | null;
}

interface Quiz {
  id: number;
  title: string;
  questions: Question[];
}

interface Feedback {
  question_id: number;
  question_text: string;
  selected_option: string;
  correct_answer: string;
  correct: boolean;
}

interface QuizResult {
  message: string;
  score: number;
  feedback: Feedback[];
}
const QuizResults: React.FC<{ quizResult: QuizResult; onBackToHome: () => void }> = ({ quizResult, onBackToHome }) => {
  const correctAnswers = quizResult.feedback.filter(item => item.correct).length;
  const incorrectAnswers = quizResult.feedback.length - correctAnswers;
  
  const performanceData = [
    { name: 'Correct', value: correctAnswers },
    { name: 'Incorrect', value: incorrectAnswers },
  ];
  
  const COLORS = ['#4CAF50', '#F44336'];

  return (
    <div className="min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Quiz Results</h1>
        
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 shadow-xl rounded-2xl p-8 mb-8 text-white">
          <h2 className="text-3xl font-semibold mb-4">Your Score</h2>
          <div className="flex items-center justify-between">
            <div className="text-6xl font-bold">{quizResult.score}%</div>
            <div style={{ width: '200px', height: '200px' }}>
              <PieChart width={200} height={200}>
                <Pie
                  data={performanceData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {performanceData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Legend />
              </PieChart>
            </div>
          </div>
          <p className="text-xl mt-4">{quizResult.message}</p>
        </div>
        
        <div className="space-y-6">
          {quizResult.feedback.map((item, index) => (
            <div key={item.question_id} className={`bg-white shadow-lg rounded-xl p-6 border-l-8 ${item.correct ? 'border-green-500' : 'border-red-500'}`}>
              <h3 className="text-xl font-semibold mb-3 text-gray-800">Question {index + 1}</h3>
              <p className="mb-3 text-gray-700">{item.question_text}</p>
              <div className="flex items-center mb-2">
                <span className="font-semibold mr-2">Your answer:</span>
                <span className={`px-3 py-1 rounded-full ${item.correct ? 'bg-green-100 text-black-800' : 'bg-red-100 text-red-800'}`}>
                  {item.selected_option}
                </span>
              </div>
              {!item.correct && (
                <div className="flex items-center">
                  <span className="font-semibold mr-2">Correct answer:</span>
                  <span className="px-3 py-1 rounded-full bg-green-100 text-black-800">
                    {item.correct_answer}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
        
        <button 
          onClick={onBackToHome} 
          className="mt-10 bg-blue-600 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg block mx-auto"
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

const Quiz: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [quiz, setQuiz] = useState<Quiz | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [quizResult, setQuizResult] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadQuiz = async () => {
      if (id) {
        setLoading(true);
        try {
          const quizData = await fetchQuizByEbookId(parseInt(id, 10));
          if (quizData) {
            setQuiz(quizData);
          } else {
            toast.info('No quiz available for this ebook.');
          }
        } catch (error) {
          console.error('Error fetching quiz:', error);
          toast.error("Failed to load quiz. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    };
    loadQuiz();
  }, [id]);

  const handleAnswerChange = (questionId: number, answer: string) => {
    setUserAnswers(prev => ({ ...prev, [questionId]: answer }));
  };

  const handleNextQuestion = () => {
    if (quiz && currentQuestion < quiz.questions.length - 1) {
      setCurrentQuestion(prev => prev + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(prev => prev - 1);
    }
  };

  const handleSubmit = async () => {
    if (!quiz) {
      toast.error("No quiz data to submit.");
      return;
    }

    const answers = quiz.questions.map((question) => ({
      question_id: question.id,
      selected_option: userAnswers[question.id] || '',
    }));

    try {
      const result = await submitQuizAnswers(quiz.id, answers);
      setQuizResult(result);
      setShowResults(true);
      toast.success(result.message);
    } catch (error) {
      toast.error("Failed to submit quiz. Please try again.");
    }
  };

  
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
      </div>
    );
  }

  if (!quiz) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-white">
        <div className="text-center text-2xl text-red-600 bg-red-100 p-8 rounded-lg shadow-lg mb-4">
          No quiz found for this eBook.
        </div>
        <button 
          onClick={() => navigate('/ebooks/home')}
          className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
        >
          Back to Home
        </button>
      </div>
    );
  }

  if (showResults && quizResult) {
    return (
      <ErrorBoundary>
        <QuizResults quizResult={quizResult} onBackToHome={() => navigate('/ebooks/home')} />
      </ErrorBoundary>
    );
  }

  const currentQuestionData = quiz.questions[currentQuestion];
  const options = currentQuestionData.type === "true_false" ? ["True", "False"] : currentQuestionData.options;

  return (
    <div className="min-h-screen bg-white py-12 px-4 sm:px-6 lg:px-8">
      <ToastContainer />
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">{quiz.title}</h1>
        <div className="bg-white shadow-2xl rounded-2xl p-8 mb-8 border-t-8 border-indigo-200">
          <div className="flex justify-between items-center mb-6">
            <p className="text-xl font-semibold text-gray-800">Question {currentQuestion + 1} of {quiz.questions.length}</p>
            <div className="w-1/2 bg-gray-200 rounded-full h-2.5">
              <div 
                className="bg-blue-600 h-2.5 rounded-full" 
                style={{width: `${((currentQuestion + 1) / quiz.questions.length) * 100}%`}}
              ></div>
            </div>
          </div>
          <p className="text-2xl mb-6 text-gray-800">{currentQuestionData.question_text}</p>
          <div className="space-y-4">
            {options?.map((option: string, index: number) => (
              <label key={index} className="flex items-center bg-gray-50 border-2 border-gray-200 rounded-xl py-4 px-6 cursor-pointer transition-all duration-300 hover:bg-blue-50 hover:border-blue-300">
                <input
                  type="radio"
                  name={`question-${currentQuestion}`}
                  value={option}
                  checked={userAnswers[currentQuestionData.id] === option}
                  onChange={() => handleAnswerChange(currentQuestionData.id, option)}
                  className="mr-4 h-5 w-5 text-blue-600"
                />
                <span className="text-lg text-gray-700">{option}</span>
              </label>
            ))}
          </div>
        </div>
        <div className="flex justify-between mt-8">
          <button
            onClick={handlePreviousQuestion}
            disabled={currentQuestion === 0}
            className="bg-gray-200 text-gray-700 font-bold py-3 px-8 rounded-full disabled:opacity-50 hover:bg-gray-300 transition duration-300 ease-in-out flex items-center"
          >
            <ChevronLeft className="mr-2" size={20} />
            Previous
          </button>
          {currentQuestion === quiz.questions.length - 1 ? (
            <button
              onClick={handleSubmit}
              className="bg-green-500 text-white font-bold py-3 px-8 rounded-full hover:bg-green-400 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex items-center"
            >
              Submit
              <Check className="ml-2" size={20} />
            </button>
          ) : (
            <button
              onClick={handleNextQuestion}
              className="bg-indigo-500 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex items-center"
            >
              Next
              <ChevronRight className="ml-2" size={20} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Quiz;