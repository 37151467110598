import React, { useEffect, useState } from 'react';
import SegmentModal from '../../uploadVideo/SegmentModal';
import TimePicker from '../../time/TimePicker'; // Import the TimePicker component
import { Segment, VideoElementsProps } from '../../../types/forms/video';
import { fetchLanguages } from '../../../services/language';

const VideoElements: React.FC<VideoElementsProps> = ({
  formData,
  handleChange,
  handleNext,
  handleBack,
  updateFormData
}) => {
  const [urlError, setUrlError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentSegment, setCurrentSegment] = useState<Segment | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [segmentError, setSegmentError] = useState<string | null>(null);
  const [languageOptions, setLanguageOptions] = useState<{ value: string; label: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getLanguages = async () => {
      try {
        const data = await fetchLanguages();
        console.log("lang",data)
        const options = data.map((lang: { value: number; label: string }) => ({
          value: lang.value,
          label: lang.label
        }));
        
        setLanguageOptions(options);
      } catch (error) {
        console.error('Failed to load languages:', error);
      } finally {
        setLoading(false);
      }
    };

    getLanguages();
  }, []);

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    if (!youtubeRegex.test(value)) {
      setUrlError('The URL should be a YouTube link.');
    } else {
      setUrlError(null);
    }
    handleChange(e);
  };

  const handleNextClick = () => {
    if (!urlError && (formData.videoURL.startsWith('http://') || formData.videoURL.startsWith('https://'))) {
      handleNext();
    } else {
      setUrlError('Please enter a valid URL starting with http:// or https://');
    }
  };

  const handleAddSegment = () => {
    setCurrentSegment(null);
    setEditingIndex(null);
    setShowModal(true);
  };

  const handleEditSegment = (index: number) => {
    setCurrentSegment(formData.segments[index]);
    setEditingIndex(index);
    setShowModal(true);
  };

  const handleSaveSegment = (segment: Segment) => {
    const isOverlap = formData.segments.some((seg, i) => {
      if (editingIndex !== null && i === editingIndex) return false;
      const segStart = new Date(`1970-01-01T${seg.startTime}:00`).getTime();
      const segEnd = new Date(`1970-01-01T${seg.endTime}:00`).getTime();
      const newSegStart = new Date(`1970-01-01T${segment.startTime}:00`).getTime();
      const newSegEnd = new Date(`1970-01-01T${segment.endTime}:00`).getTime();
      return (newSegStart < segEnd && newSegEnd > segStart);
    });

    if (isOverlap) {
      setSegmentError('Invalid segment: overlaps with an existing segment.');
      setShowModal(false);
    } else {
      const updatedSegments = editingIndex !== null
        ? formData.segments.map((seg, i) => (i === editingIndex ? segment : seg))
        : [...formData.segments, segment];
      updateFormData({ segments: updatedSegments });
      setShowModal(false);
      setSegmentError(null);
    }
  };

  const handleRemoveSegment = (index: number) => {
    const updatedSegments = formData.segments.filter((_, i) => i !== index);
    updateFormData({ segments: updatedSegments });
  };

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateFormData({
      media_details: {
        ...formData.media_details, // Spread the existing media_details object
        originalLanguage: e.target.value // Update only the originalLanguage field
      }
    });
  };

  console.log("ff",formData.segments,"ee",currentSegment);
  

  return (
    <div className="max-w-3xl mx-auto p-6 space-y-4" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
      <div>
        <label htmlFor="url" className="block text-lg font-medium text-gray-700">
          Media Link <span className="text-red-500">(required)</span>
        </label>
        <input
          type="text"
          name="videoURL"
          id="url"
          value={formData.videoURL}
          onChange={handleUrlChange}
          required
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        />
        {urlError && <p className="text-red-500 text-sm mt-2">{urlError}</p>}
      </div>
      
      <div>
        <label htmlFor="segments" className="block text-lg font-medium text-gray-700">Segmentation</label>
        <button
          type="button"
          onClick={handleAddSegment}
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm text-left focus:border-blue-500 focus:ring-blue-500 text-lg"
        >
          Add Segment
        </button>
        {segmentError && <p className="text-red-500 text-sm mt-2">{segmentError}</p>}
        <div className="mt-2 space-y-2">
          {formData.segments.map((segment, index) => (
            <div key={index} className="flex flex-col space-y-1 border p-2 rounded-md">
              <span><strong>Title:</strong> {segment.title}</span>
              <span><strong>Start Time:</strong> {segment.startTime}</span>
              <span><strong>End Time:</strong> {segment.endTime}</span>
              <span><strong>Transcripts:</strong></span>
              <ul className="pl-4 list-disc">
                {segment.transcripts.map((transcript, idx) => (
                  <li key={idx}>{transcript.language}: {transcript.transcript}</li>
                ))}
              </ul>
              <div className="flex space-x-4">
                <button
                  type="button"
                  onClick={() => handleEditSegment(index)}
                  className="text-blue-500 hover:underline"
                >
                  Edit
                </button>
                <button
                  type="button"
                  onClick={() => handleRemoveSegment(index)}
                  className="text-red-500 hover:underline"
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <label htmlFor="originalLanguage" className="block text-lg font-medium text-gray-700">Original Language <span className="text-red-500">(required)</span></label>
        <select
          id="originalLanguage"
          name="media_details.originalLanguage"
          value={formData.media_details.originalLanguage}
          onChange={handleLanguageChange}
          className="mt-2 block w-full h-12 px-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-lg"
        >
          <option value="" disabled>Select a language</option>
          {languageOptions.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={handleBack}
          className="mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleNextClick}
          className="mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Next
        </button>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full overflow-y-auto" style={{ maxHeight: '90vh' }}>
            <SegmentModal
              onClose={() => setShowModal(false)}
              onSave={handleSaveSegment}
              segment={currentSegment}
              segments={formData.segments}
              setSegmentError={setSegmentError}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoElements;
