// src/components/TranscriptWidget.jsx

import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { FaSearch } from "react-icons/fa";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import { fetchTranscriptsByID } from "../services/generalVideoServices";
import { isRTL } from "../services/script";
import { highlightHtml, resetMatchIndexCounter } from "../utils/highlightHtml";

import {
  combinedSegmentTranscript,
  Segment,
  Transcript,
} from "../types/domain";

import { MutableRefObject } from "react";

export function TranscriptWidget({
  videoId,
  playerRef,
  transcripts,
}: {
  videoId: number;
  playerRef: MutableRefObject<ReactPlayer | null>;
  transcripts: Transcript[];
}) {
  // Existing states
  const [searchTerm, setSearchTerm] = useState("");
  const [totalTranscriptSearchTerm, setTotalTranscriptSearchTerm] = useState("");
  const [combinedSegmentsTranscripts, setCombinedSegmentsTranscripts] = useState<combinedSegmentTranscript[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [iscollapsed, setIsCollapsed] = useState(true);

  // === New states for match navigation ===
  // We'll store all "highlighted-match" elements after rendering
  const [matchElements, setMatchElements] = useState<HTMLElement[]>([]);
  // The currently focused match index
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);

  const transcriptRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      const { combinedSegmentTranscripts } = await fetchTranscriptsByID(Number(videoId));
      setCombinedSegmentsTranscripts(combinedSegmentTranscripts);
      if (transcripts.length > 0) {
        setSelectedLanguage(transcripts[0].transcriptionLanguage);
      }
    };
    fetchData();
  }, [videoId, transcripts]);

  // Handle searches
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const handleTotalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTotalTranscriptSearchTerm(e.target.value);
    // Also reset the match navigation to start from first match
    setCurrentMatchIndex(0);
  };

  // Convert times
  const timeToSeconds = (time: string) => {
    const [hours, minutes, seconds] = time.split(":").map((t) => parseInt(t, 10));
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Basic highlight for segment text
  const escapeRegExp = (string: string) =>
    string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  const highlightMatch = (text: string) => {
    if (!text || !searchTerm) return text;

    const regex = new RegExp(`(${escapeRegExp(searchTerm)})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={i} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // 1) highlight the full transcript
  // 2) parse it into React elements
  const highlightMatchForTotalTranscript = (htmlStr: string) => {
    if (!htmlStr) return null;

    // Reset the match index counter so each highlight starts at 0
    resetMatchIndexCounter();

    if (!totalTranscriptSearchTerm) {
      // No search, just parse the raw HTML
      return parse(htmlStr);
    }

    // user typed something: highlight it
    const newHtml = highlightHtml(htmlStr, totalTranscriptSearchTerm);
    return parse(newHtml);
  };

  // Filter for segment transcripts
  const filteredCSTs = useMemo(() => {
    return combinedSegmentsTranscripts.filter((CST) => {
      const descMatch = CST.segment.description
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      const matchingTranscript = CST.transcripts.find(
        (t) => t.transcriptionLanguage === selectedLanguage
      );
      const transcriptMatch = matchingTranscript
        ? matchingTranscript.transcription
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        : false;

      return descMatch || transcriptMatch;
    });
  }, [combinedSegmentsTranscripts, searchTerm, selectedLanguage]);

  // Play segment
  const handlePlaySegment = (segment: Segment) => {
    if (!playerRef.current) return;
    const startTime = timeToSeconds(segment.startTime);
    playerRef.current.seekTo(startTime, "seconds");
  };

  // Segment displayer
  const segmentDisplayer = (
    cst: combinedSegmentTranscript,
    idx: number
  ) => {
    const segment = cst.segment;
    const transcriptInLang =
      cst.transcripts.find((tr) => tr.transcriptionLanguage === selectedLanguage)
      ?? cst.transcripts[0];

    return (
      <React.Fragment key={idx}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              handlePlaySegment(segment);
            }}
          >
            <ListItemText
              primary={`${segment.startTime} - ${segment.endTime}`}
              secondary={
                <div
                  style={{
                    direction: isRTL(transcriptInLang?.transcription)
                      ? "rtl"
                      : "ltr",
                    textAlign: isRTL(transcriptInLang?.transcription)
                      ? "right"
                      : "left",
                  }}
                >
                  <div style={{ margin: "0.5rem 0" }}>
                    <strong>{highlightMatch(segment.description)}</strong>
                  </div>
                  <div style={{ margin: "0.5rem 0" }}>
                    {highlightMatch(transcriptInLang?.transcription || "")}
                  </div>
                  <Typography
                    component="span"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlaySegment(segment);
                    }}
                    sx={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Play Segment
                  </Typography>
                </div>
              }
            />
          </ListItemButton>
        </ListItem>
        <Divider />
      </React.Fragment>
    );
  };

  // Language selection
  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setSelectedLanguage(event.target.value);
    // Reset search / match index?
  };

  // The chosen transcript text
  const selectedFullTranscript = useMemo(() => {
    if (!selectedLanguage || !transcripts.length) return "";
    const match = transcripts.find(
      (t) => t.transcriptionLanguage === selectedLanguage
    );
    return match?.transcription ?? "";
  }, [selectedLanguage, transcripts]);

  // ===== After the Full Transcript is rendered, find all matches in the DOM =====
  // We'll do that in a useEffect that runs after the DOM is updated.
  // Then we can store them in state so we can navigate among them.
  useEffect(() => {
    // Wait for rendering to finish
    if (!transcriptRef.current) return;

    // Query all highlighted matches
    const els = transcriptRef.current.querySelectorAll<HTMLElement>(
      ".highlighted-match"
    );
    // Convert NodeList to array
    const arr = Array.from(els);
    setMatchElements(arr);

    // Reset current index if new search
    setCurrentMatchIndex(0);
  }, [totalTranscriptSearchTerm, selectedFullTranscript]);

  // Move to current match
  useEffect(() => {
    if (!matchElements.length) return;
    // If out of range, clamp
    if (currentMatchIndex < 0) {
      setCurrentMatchIndex(matchElements.length - 1);
      return;
    }
    if (currentMatchIndex >= matchElements.length) {
      setCurrentMatchIndex(0);
      return;
    }

    const el = matchElements[currentMatchIndex];
    el.scrollIntoView({ behavior: "smooth", block: "center" });
    // Optionally highlight the "active" match differently
    // e.g., add a CSS class or style
    matchElements.forEach((m) => m.style.outline = "");
    el.style.outline = "2px solid orange";
  }, [currentMatchIndex, matchElements]);

  // Handle next/prev
  const handleNextMatch = () => {
    if (!matchElements.length) return;
    setCurrentMatchIndex((prev) => prev + 1);
  };
  const handlePrevMatch = () => {
    if (!matchElements.length) return;
    setCurrentMatchIndex((prev) => prev - 1);
  };

  if (!combinedSegmentsTranscripts.length) return null;

  return (
    <Grid container spacing={2}>
      {/* Left */}
      <Grid item xs={iscollapsed ? 1 : 4}>
        <Card
          sx={{
            width: iscollapsed ? "fit-content" : "100%",
            height: "fit-content",
          }}
        >
          <Button onClick={() => setIsCollapsed(!iscollapsed)}>
            {iscollapsed ? "Transcript" : "Hide"}
          </Button>
          {!iscollapsed && (
            <>
              <CardHeader
                title={
                  <FormControl fullWidth>
                    <InputLabel id="transcript-language-label">
                      Transcript Version
                    </InputLabel>
                    <Select
                      labelId="transcript-language-label"
                      value={selectedLanguage}
                      label="Transcript Version"
                      onChange={handleChangeLanguage}
                    >
                      {transcripts.map((t, i) => (
                        <MenuItem key={i} value={t.transcriptionLanguage}>
                          {t.language}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                subheader={
                  <Box sx={{ display: "flex", alignItems: "end" }}>
                    <TextField
                      sx={{ width: "100%" }}
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FaSearch style={{ marginRight: "8px" }} />
                          <>Search</>
                        </Box>
                      }
                      variant="standard"
                      onChange={handleSearch}
                    />
                  </Box>
                }
              />
              <CardContent>
                <List sx={{ overflow: "auto", height: "70vh" }}>
                  {filteredCSTs.map((cst, i) => segmentDisplayer(cst, i))}
                </List>
              </CardContent>
            </>
          )}
        </Card>
      </Grid>

      {/* Right: Full Transcript */}
      <Grid item xs={iscollapsed ? 11 : 8}>
  {!iscollapsed && (
    <Card>
      <CardHeader
        title="Full Transcript"
        subheader={
          <Box sx={{ display: "flex", alignItems: "end", gap: 1 }}>
            <TextField
              sx={{ width: "100%" }}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FaSearch style={{ marginRight: "8px" }} />
                  <>Search</>
                </Box>
              }
              variant="standard"
              onChange={handleTotalSearch}
            />
            {/* Buttons to navigate among matches */}
            <Button
              variant="outlined"
              size="small"
              onClick={handlePrevMatch}
              disabled={!matchElements.length}
            >
              Prev
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleNextMatch}
              disabled={!matchElements.length}
            >
              Next
            </Button>
          </Box>
        }
      />
      <CardContent
        ref={transcriptRef} // Attach ref to this container
        sx={{
          maxHeight: "79.5vh",
          overflow: "auto",
        }}
        style={{
          direction: isRTL(selectedFullTranscript) ? "rtl" : "ltr",
          textAlign: isRTL(selectedFullTranscript) ? "right" : "left",
          fontSize: "1.2rem", // Adjust font size here (default is 1rem)
          lineHeight: "1.6", // Adjust line height for better readability
        }}
      >
        {highlightMatchForTotalTranscript(selectedFullTranscript)}
      </CardContent>
    </Card>
  )}
</Grid>
    </Grid>
  );
}
