// src/components/LocationPicker.tsx
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix the default icon issue with Leaflet in React
delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

interface LocationPickerProps {
  position: { lat: number; lng: number } | null;
  setPosition: (pos: { lat: number; lng: number } | null) => void;
}

const LocationPicker: React.FC<LocationPickerProps> = ({ position, setPosition }) => {
  // Initialize state to handle map events
  const [mapPosition, setMapPosition] = useState(position || { lat: 51.505, lng: -0.09 });

  // Track position changes from the map
  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        setMapPosition(e.latlng); // Update position on map click
        setPosition(e.latlng); // Update the parent component's state
      },
      locationfound(e) {
        setMapPosition(e.latlng); // Update position on location found
        setPosition(e.latlng); // Update the parent component's state
      },
    });

    return mapPosition === null ? null : (
      <Marker position={mapPosition}></Marker> // Show marker at the updated position
    );
  };

  useEffect(() => {
    // Update mapPosition if the parent component's position changes
    if (position && (position.lat !== mapPosition.lat || position.lng !== mapPosition.lng)) {
      setMapPosition(position);
    }
  }, [position, mapPosition]);

  return (
    <MapContainer
      center={mapPosition} // Center the map on the current position
      zoom={13}
      style={{ height: '400px', width: '100%' }}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker />
    </MapContainer>
  );
};

export default LocationPicker;
