import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faGooglePlusG,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

const Footer: React.FC = () => {
  return (
    <footer className="relative mt-20">
      {/* Decorative Top Border */}
      <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-transparent via-green-800 to-transparent"></div>
      {/* Main Footer Content */}
      <div className="bg-gradient-to-b from-green-900 to-green-950 text-white py-12">
        <div className="container mx-auto px-6">
          <div className="flex flex-col items-center space-y-8">
            {/* Logo and Title Section */}
            <div className="flex items-center space-x-6">
              <img
                src="/assets/ifrane.png"
                alt="Logo"
                className="h-16 rounded-full shadow-lg border-2 border-green-700 p-1 hover:border-green-500 transition-colors duration-300"
              />
              <div className="text-center">
                <h3 className="text-3xl font-semibold mb-2">Oral Archiving Platform</h3>
                <div className="w-full h-0.5 bg-gradient-to-r from-transparent via-green-500 to-transparent"></div>
              </div>
            </div>
            {/* Social Media Section */}
            <div className="flex flex-wrap justify-center gap-6">
              {[
                { icon: faFacebookF, label: 'Facebook', color: 'hover:text-blue-400' },
                { icon: faTwitter, label: 'Twitter', color: 'hover:text-blue-300' },
                { icon: faGooglePlusG, label: 'Google Plus', color: 'hover:text-red-400' },
                { icon: faLinkedinIn, label: 'LinkedIn', color: 'hover:text-blue-500' },
                { icon: faYoutube, label: 'YouTube', color: 'hover:text-red-500' },
              ].map((social, index) => (
                <a
                  key={index}
                  href="#"
                  className={`p-3 bg-green-800/30 rounded-full ${social.color} transform hover:scale-110 transition-all duration-300 hover:bg-white/10`}
                  aria-label={social.label}
                >
                  <FontAwesomeIcon icon={social.icon} size="lg" className="w-6 h-6" />
                </a>
              ))}
            </div>
            {/* Copyright Line */}
            <div className="text-sm text-black-300/80 mt-6">
              © 2024 Oral Archiving Platform. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;