import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { FaTh, FaList, FaEllipsisV, FaFilter, FaClock, FaPlus, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Media, Category } from '../types/domain';
import { withoutAuthfetchMedia, getCategory } from '../services/media';
import { addToWatchLater } from '../services/watchLater';
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
import { addVideoToPlaylist, createPlaylist, fetchUserPlaylists } from '../services/playlistService';
import Pagination from '../components/uploadVideo/Pagination';
import { getYoutubeThumbnail } from '../utils/helpers/getYoutubeThumbnail';
import { PlaylistWithSelectedVideo as Playlist } from '../types/domain';

const Home: React.FC<{ searchQuery: string }> = ({ searchQuery }) => {
  const [videos, setVideos] = useState<Media[]>([]);
  const [filteredVideos, setFilteredVideos] = useState<Media[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);  // Update page state to start from 1
  const [videosPerPage, setVideosPerPage] = useState<number>(5);  // Default videos per page
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [categories, setCategories] = useState<Category[]>([]);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const navigate = useNavigate();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  // Playlist modal states
  const [isPlaylistModalOpen, setIsPlaylistModalOpen] = useState<boolean>(false);
  const [playlistName, setPlaylistName] = useState<string>('');
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [selectedVideo, setSelectedVideo] = useState<Media | null>(null);

  // Fetch user playlists when the modal opens
  useEffect(() => {
    const loadPlaylists = async () => {
      try {
        const userPlaylists = await fetchUserPlaylists();
        setPlaylists(userPlaylists.results);
      } catch (error) {
        toast.error('Failed to load playlists.');
      }
    };
    if (isPlaylistModalOpen) {
      loadPlaylists();
    }
  }, [isPlaylistModalOpen]);

  const handleAddVideoToExistingPlaylist = async (playlistId: string) => {

    if (selectedVideo) {
      try {
        console.log()
        await addVideoToPlaylist(playlistId, selectedVideo.media_details.id);
        toast.success('Video added to playlist');
        setIsPlaylistModalOpen(false);
      } catch (error) {
        toast.error('Failed to add video to playlist');
      }
    }
  };

  const handleCreatePlaylist = async () => {
    if (!playlistName) {
      toast.error('Please provide a playlist name');
      return;
    }

    try {
      const newPlaylist = await createPlaylist(playlistName, 'New playlist description');
      setPlaylists((prevPlaylists) =>
        Array.isArray(prevPlaylists) ? [...prevPlaylists, newPlaylist] : [newPlaylist]
      );

      setPlaylistName('');

      // Add video to the newly created playlist
      if (selectedVideo) {
        await addVideoToPlaylist(newPlaylist.id, selectedVideo.media_details.id);
        toast.success('Playlist created and video added successfully');
      }
    } catch (error) {
      toast.error('Failed to create playlist');
    }
  };

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const mediaData = await withoutAuthfetchMedia();
        setVideos(mediaData.results);
        setFilteredVideos(mediaData.results);
      } catch (error) {
        console.error('Error loading videos:', error);
      }
    };

    const loadCategories = async () => {
      try {
        const categoryData = await getCategory();
        setCategories([{ id: 0, name: 'All' }, ...categoryData]);
      } catch (error) {
        console.error('Error loading categories:', error);
      }
    };

    loadVideos();
    loadCategories();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      const filtered = videos.filter((video: Media) =>
        (selectedCategory === 'All' || video.media_details.category_name === selectedCategory) &&
        video.media_details.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredVideos(filtered);
    };

    applyFilters();
  }, [searchQuery, selectedCategory, videos]);

  const handlePlayVideo = (video: Media) => {
    navigate(`/videos/watch/${video.id}`, { state: { video } });
  };

  const handleAddToWatchLater = async (video: Media) => {
    try {
      await addToWatchLater(video.media_details.id);
      toast.success(`Video Added to watch later: ${video.media_details.title}`);
    } catch (error) {
      console.error('Error adding video to Watch Later:', error);
    }
  };

 

  const handleOpenPlaylistModal = (video: Media) => {
    setSelectedVideo(video);
    setIsPlaylistModalOpen(true);
  };

  // Pagination calculations
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = filteredVideos.slice(indexOfFirstVideo, indexOfLastVideo);
  const totalVideos = filteredVideos.length;
  const totalPages = Math.ceil(totalVideos / videosPerPage);

  return (
    <div className="p-4">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4 text-primary">Home</h1>
      <div className="mb-4 flex justify-center">
        <div className="flex space-x-2 overflow-x-auto">
          {categories.map((category) => (
            <button
              key={category.id}
              className={`bg-gray-200 text-black rounded-full px-4 py-2 ${
                selectedCategory === category.name ? 'bg-gray-400' : ''
              }`}
              onClick={() => setSelectedCategory(category.name)}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>
      <div className="flex justify-end mb-4">
        <FaTh
          className={`cursor-pointer mx-2 ${viewMode === 'grid' ? 'text-blue-500' : 'text-gray-500'}`}
          onClick={() => setViewMode('grid')}
        />
        <FaList
          className={`cursor-pointer mx-2 ${viewMode === 'list' ? 'text-blue-500' : 'text-gray-500'}`}
          onClick={() => setViewMode('list')}
        />
        <FaFilter
          className="cursor-pointer mx-2 text-gray-500 hover:text-blue-500"
          onClick={() => setIsFilterModalOpen(true)}
        />
      </div>
      <div
        className={
          viewMode === 'grid'
            ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'
            : 'flex flex-col space-y-4'
        }
      >
        {currentVideos.map((video) => (
          <div
            key={video.id}
            className={`p-4 bg-white rounded-lg shadow-lg transform transition duration-300 hover:scale-105 relative ${
              viewMode === 'list' ? 'flex items-center space-x-4' : ''
            }`}
          >
            <img
              className={`cursor-pointer ${viewMode === 'list' ? 'w-48 h-24' : 'w-full h-48'} object-cover rounded-lg`}
              src={getYoutubeThumbnail(video.videoURL)}
              alt={video.media_details.title}
              onClick={() => handlePlayVideo(video)}
            />
            <div className={`${viewMode === 'list' ? 'flex-1' : 'mt-2'}`}>
              <h2 className="text-lg font-bold">{video.media_details.title}</h2>
              <p className="text-sm text-gray-600">{video.media_details.description}</p>
              <p className="text-sm text-gray-600">{video.media_details.category_name}</p>
              <div className="flex justify-between items-center mt-2">
                <span className="text-sm text-gray-600">{video.media_details.views} views</span>
                <span className="text-sm text-gray-600">{video.media_details.likes} likes</span>
              </div>
            </div>
            <div className="absolute top-2 right-2 flex items-center space-x-2">
              <FaClock
                className="cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => handleAddToWatchLater(video)}
              />
              <FaPlus
                className="cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => handleOpenPlaylistModal(video)}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Replace pagination with the custom Pagination component */}
      {totalVideos > videosPerPage && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page: number) => setCurrentPage(page)}
          itemsPerPage={videosPerPage}
          totalItems={totalVideos}
          setItemsPerPage={setVideosPerPage}
        />
      )}

      <Modal
        isOpen={isPlaylistModalOpen}
        onRequestClose={() => setIsPlaylistModalOpen(false)}
        className="p-4 bg-white rounded-lg shadow-lg max-w-md mx-auto mt-20 relative"
      >
        <button
          className="absolute top-0 right-0 p-2"
          onClick={() => setIsPlaylistModalOpen(false)}
        >
          <FaTimes size={20} />
        </button>

        <h2 className="text-xl font-bold mb-4 text-center">Add to Playlist</h2>

        <div className="flex flex-col items-center justify-center w-full">
          <ul className="mb-4 w-1/2">
            {Array.isArray(playlists) && playlists.length > 0 ? (
              playlists.map((playlist) => (
                <li
                  key={playlist.id}
                  className="cursor-pointer mb-2 p-2 bg-gray-200 rounded-lg hover:bg-gray-300 text-center"
                  onClick={() => handleAddVideoToExistingPlaylist(playlist.id)}
                >
                  {playlist.name}
                </li>
              ))
            ) : (
              <p>No playlists available</p>
            )}
          </ul>

          <div className="w-1/2">
            <input
              type="text"
              placeholder="New playlist name"
              value={playlistName}
              onChange={(e) => setPlaylistName(e.target.value)}
              className="p-2 border border-gray-300 rounded-lg w-full"
            />
          </div>

          <button
            className="bg-blue-500 text-white rounded-lg p-2 mt-2 w-1/3"
            onClick={handleCreatePlaylist}
          >
            Create Playlist
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Home;
