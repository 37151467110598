const BASE_URL = process.env.REACT_APP_Django_API_URL;
export const fetchMedia = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v/video/`,
        {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
          }
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch media');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching media:', error);
      throw error;
    }
  };
  export const withoutAuthfetchMedia = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/v/video/`); // No Authorization header
  
      if (!response.ok) {
        throw new Error('Failed to fetch media');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching media:', error);
      throw error;
    }
  };
  
  export const getCategory = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/m/category/`);
      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }
      const data = await response.json();
      
      // Extract categories from the 'results' field
      if (!Array.isArray(data.results)) {
        throw new Error('Category data is not an array');
      }
  
      return data.results;
    } catch (error) {
      console.error('Error fetching categories:', error);
      return []; // Return an empty array in case of error
    }
  };
    