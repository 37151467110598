import { useEffect, useState } from "react";
import { Comment } from "../types/domain";
import CommentDisplay from "./CommentDisplay";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import {
  addComment,
  fetchCommentsByID,
} from "../services/generalVideoServices";

export default function CommentSection({ videoId }: { videoId: number }) {
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentToUpload, setCommentToUpload] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("newest");
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const comments = await fetchCommentsByID(videoId);
      setComments(comments);
    };

    fetchData();
  }, [videoId, refresh]);

  const sortedComments = [...comments].sort((a, b) => {
    if (sortOrder === "oldest") {
      return a.id - b.id;
    } else {
      return b.id - a.id;
    }
  });

  const reportComment = (commentId: number) => {
    alert(`Reported comment successfully`);
  };

  const handleAddComment = async () => {
    if (commentToUpload === "") {
      alert("Comment cannot be empty");
      return;
    }

    await addComment(videoId, commentToUpload);

    setCommentToUpload("");
    refreshComments();
  };

  const refreshComments = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <div className="font-bold text-2xl flex justify-between mt-2">
        <div className="font-bold text-2xl ">Comments</div>
        
        <FormControl>
          <InputLabel id="demo-simple-select-label">Sort</InputLabel>
          <Select
            value={sortOrder}
            label="Sort"
            onChange={(e) => {
              setSortOrder(e.target.value);
            }}
          >
            <MenuItem value="newest">newest</MenuItem>
            <MenuItem value="oldest">oldest</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div>
        <div className="">
          <textarea
            className="border-2 rounded-md m-1 p-1 w-full"
            value={commentToUpload}
            onChange={(e) => {
              setCommentToUpload(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleAddComment}
          >
            Submit
          </Button>
        </div>

        {sortedComments.map((comment, index) => (
          <CommentDisplay
            key={index}
            mediaID={videoId}
            comment={comment}
            onReport={reportComment}
            refreshComments={refreshComments}
          />
        ))}
      </div>
    </>
  );
}
