import React, { useState, FormEvent } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { resetPassword } from '../services/authService';

const ResetPassword: React.FC = () => {
  const { uid, token } = useParams<{ uid: string; token: string }>();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long.';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    }
    if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter.';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number.';
    }
    if (!/[@$!%*?&#]/.test(password)) {
      return 'Password must contain at least one special character (@, $, !, %, *, ?, &, or #).';
    }
    return null;
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const passwordError = validatePassword(password);
    if (passwordError) {
      toast.error(passwordError);
      return;
    }

    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    const result = await resetPassword(token || '', uid || '', password);
    if (result.success) {
      toast.success(result.message);
      navigate('/signin');
    } else {
      toast.error(result.message);
    }
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-semibold mb-4 text-center text-black-600">Reset Password</h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-y-4">
          <div className="relative">
            <label htmlFor="password" className="block mb-1 text-sm font-semibold text-gray-700">
              New Password
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              className="w-full py-2 px-4 border border-gray-300 rounded-lg text-sm outline-none focus:border-green-500"
              placeholder="Enter your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute top-1/2 right-4 transform -translate-y-0 text-gray-500 focus:outline-none hover:text-gray-700"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="relative">
            <label htmlFor="confirm-password" className="block mb-1 text-sm font-semibold text-gray-700">
              Confirm Password
            </label>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirm-password"
              className="w-full py-2 px-4 border border-gray-300 rounded-lg text-sm outline-none focus:border-green-500"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              className="absolute top-1/2 right-4 transform -translate-y-0 text-gray-500 focus:outline-none hover:text-gray-700"
              >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="flex gap-4">
            <button
              type="button"
              onClick={() => navigate('/signin')}
              className="py-2 px-4 bg-gray-400 text-white rounded-lg text-sm font-semibold hover:bg-gray-500 transition duration-200"
            >
              Back
            </button>
            <button
              type="submit"
              className="py-2 px-4 bg-green-500 text-white rounded-lg text-sm font-semibold hover:bg-green-600 transition duration-200"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
