

import { Book, BookDetail, SimpleBook } from '../types/ebooks/ebooks';

import axios from 'axios';
const BASE_URL = process.env.REACT_APP_Django_API_URL;
const token = localStorage.getItem('authToken');
const headers = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`,
};


export interface Question {
  id: number;
  quiz: number;
  question_text: string;
  type: 'true_false' | 'multiple_choice';
  correct_answer: string | null;
  options: string[] | null;
  correct_option: string | null;
}

export interface Quiz {
  id: number;
  title: string;
  ebook: number;
  questions: Question[];
}

interface QuizAnswer {
  question_id: number;
  selected_option: string;
}
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  // Formatting to YYYY-MM-DD
  return date.toISOString().split('T')[0]; 
};



interface QuizAnswer {
  question_id: number;
  selected_option: string;
}

export const addEbook = async (data: FormData) => {
  const token = localStorage.getItem('authToken');
  
  if (!token) {
    throw new Error('Authentication token not found');
  }

  try {
    // Debug log the FormData contents using Array.from
    console.log('=== FormData Contents ===');
    const formDataObject: Record<string, any> = {};
    Array.from(data.entries()).forEach(([key, value]) => {
      formDataObject[key] = value instanceof File 
        ? { name: value.name, type: value.type, size: value.size }
        : value;
    });

    console.log('FormData as object:', formDataObject);

    const response = await axios.post(`${BASE_URL}/api/e/ebooks/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    });
    
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorData = error.response?.data;
      console.error('Error Response Data:', errorData);
      
      if (typeof errorData === 'object') {
        const errorMessage = Object.entries(errorData)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', ');
        throw new Error(`Failed to add ebook: ${errorMessage}`);
      } else {
        throw new Error(`Failed to add ebook: ${String(errorData)}`);
      }
    }
    throw error;
  }
};

export const deleteEbook = async (ebookId: number): Promise<void> => {
  const token = localStorage.getItem('authToken');
  
  if (!token) {
    throw new Error('No authentication token found');
  }

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };

  try {
    await axios.delete(`${BASE_URL}/api/e/ebooks/${ebookId}/`, { headers });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // If the error has a response, it means the request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        throw error;
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error request:', error.request);
        throw new Error('No response received from the server');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
        throw new Error('Error setting up the request');
      }
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
};
export async function fetchCategories() {
  try {
    const response = await axios.get(`${BASE_URL}/api/m/category/`);
    return response.data;  // Assuming the API returns an array of categories
  } catch (error) {
    console.error('Failed to fetch categories:', error);
    throw error;  // Re-throw to handle it in the component
  }
}

export async function fetchChannels() {
  try {
    const response = await axios.get(`${BASE_URL}/api/c/channel/`);
    return response.data;  // Assuming the API returns an array of channels
  } catch (error) {
    console.error('Failed to fetch channels:', error);
    throw error;  // Re-throw to handle it in the component
  }
}
// Fetch the taken quizzes
export const fetchTakenQuizzes = async (): Promise<any[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/api/e/submissions/my_quizzes/`, {
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${token}`,
      },
    });
    return response.data; // Adjust according to the actual data structure if needed
  } catch (error) {
    console.error('Error fetching taken quizzes:', error);
    throw error;
  }
};
export const patchQuestion = async (questionId: number, data: Partial<Question>): Promise<Question> => {
  try {
    const response = await axios.patch(`${BASE_URL}/api/e/questions/${questionId}/`, data, { headers });
    return response.data;
  } catch (error) {
    console.error('Error patching question:', error);
    throw error;
  }
};

export const fetchQuizzesByEbookId = async (ebookId: number): Promise<Quiz[]> => {
  try {
    const response = await axios.get<{ results: Quiz[] }>(`${BASE_URL}/api/e/quizzes/`, {
      headers,
      params: { ebook: ebookId },
    });
    
    // Filter the results to find quizzes matching the ebookId
    const matchingQuizzes = response.data.results.filter((quiz: Quiz) => quiz.ebook === ebookId);

    if (matchingQuizzes.length > 0) {
      return matchingQuizzes;
    } else {
      console.log(`No quizzes found for ebook ID: ${ebookId}`);
      return [];
    }
  } catch (error) {
    console.error('Error fetching quizzes:', error);
    throw error;
  }
};
export const fetchQuizByEbookId = async (ebookId: number): Promise<Quiz | null> => {
  try {
    const response = await axios.get<{ results: Quiz[] }>(`${BASE_URL}/api/e/quizzes/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ebook: ebookId,
      }
    });

    // Filter the results to find the quiz matching the ebookId
    const matchingQuiz = response.data.results.find(quiz => quiz.ebook === ebookId);

    if (matchingQuiz) {
      return matchingQuiz;
    } else {
      console.log(`No quiz found for ebook ID: ${ebookId}`);
      return null;
    }
  } catch (error) {
    console.error('Error fetching quiz:', error);
    throw error;
  }
};
export const patchQuiz = async (quizId: number, data: { title: string }): Promise<Quiz> => {
  try {
    const response = await axios.patch(`${BASE_URL}/api/e/quizzes/${quizId}/`, data, { headers });
    return response.data;
  } catch (error) {
    console.error('Error patching quiz:', error);
    throw error;
  }
};
// Types for the function parameters
export const submitQuizAnswers = async (quizId: number, answers: QuizAnswer[]): Promise<any> => {
  const response = await axios.post(`${BASE_URL}/api/e/quizzes/${quizId}/submit/`, {
    answers,
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  });
  return response.data;
};

// Fetch comments for a specific book
export const fetchComments = async (ebookId: number): Promise<any[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/api/e/ebooks/${ebookId}/comments/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Add the token in the request header
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching comments:', error);
    throw error;
  }
};

// Post a new comment
export const postComment = async (ebookId: number, body: string, parentId: number | null = null): Promise<any> => {
  try {
    const response = await axios.post(`${BASE_URL}/api/e/ebooks/${ebookId}/comments/`, {
      body,
      parent: parentId, // parent is null for top-level comments
    }, {
      headers: {
        ContentType: 'application/json',
        Authorization: `Bearer ${token}`, // Add the token in the request header
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting comment:', error);
    throw error;
  }
};
export const fetchBooks = async (): Promise<Book[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/api/e/ebooks/`);
    return response.data.results.map((book: any) => ({
      id: book.id,
      title: book.title,
      description: book.description,
      cover: book.thumbnail,
      pdfUrl: book.file,
      author: book.uploader_name,
      year: new Date(book.uploadDate).getFullYear(),
      date: formatDate(book.uploadDate), 
      genre: book.category_name, // Assuming this maps to your genre

    }));
  } catch (error) {
    console.error('Error fetching books:', error);
    throw error;
  }
};
export const fetchUserBooks = async (): Promise<Book[]> => {
  const token = localStorage.getItem('authToken');
  
  if (!token) {
    throw new Error('Authentication token not found');
  }

  try {
    console.log('Fetching user books with token:', token);
    const url = `${BASE_URL}/api/e/ebooks/mybooks/`;
    console.log('Request URL:', url);

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    console.log('Response:', response.data);

    if (!response.data || !response.data.results) {
      console.warn('No results found in response');
      return [];
    }

    return response.data.results.map((book: any) => ({
      id: book.id,
      title: book.title,
      description: book.description,
      cover: book.thumbnail,
      pdfUrl: book.file,
      author: book.uploader_name || 'Unknown Author',
      year: book.uploadDate ? new Date(book.uploadDate).getFullYear() : new Date().getFullYear(),
      date: formatDate(book.uploadDate || new Date().toISOString()),
      genre: book.category_name || 'Unknown Genre',
    }));
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios Error Details:', {
        status: error.response?.status,
        data: error.response?.data,
        headers: error.response?.headers
      });

      if (error.response?.status === 401) {
        throw new Error('Please log in to view your books');
      }
      if (error.response?.status === 404) {
        console.error('Endpoint not found. Please check API configuration.');
      }
    }
    throw error;
  }
};
export const fetchBook = async (id: number): Promise<BookDetail | undefined> => {
  try {
    const response = await axios.get(`${BASE_URL}/api/e/ebooks/${id}/`);
    const bookData = response.data;

    return {
      id: bookData.id,
      title: bookData.title,
      author: bookData.uploader_name || 'Unknown Author', // Map uploader_name to author
      year: new Date(bookData.uploadDate).getFullYear(), // Convert upload date to year
      cover: bookData.thumbnail,
      genre: bookData.category_name || 'Unknown Genre',
      longSynopsis: bookData.description || 'No synopsis available', // Assuming description is used for synopsis
   
      pdfUrl: bookData.file || '', // Assuming this is the field for the PDF file URL
      description: bookData.description || 'No description available', // Ensure description is there
      date: bookData.uploadDate || 'Unknown Date', // If no date is returned
    };
  } catch (error) {
    console.error('Error fetching book details:', error);
    throw error;
  }
};
// Fetch "Read Later" books from the backend
// Function to add a book to the "Read Later" list
export const addBookToReadLater = async (mediaId: number): Promise<string> => {
  const token = localStorage.getItem('authToken'); // Retrieve the token from local storage
  if (!token) {
    throw new Error('Authentication token is missing.');
  }

  try {
    const response = await axios.post(
      `${BASE_URL}/api/e/read-later/add/`,
      { media_id: mediaId },
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Include the token in the Authorization header
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.message; // Assuming the response contains a success message
  } catch (error) {
    console.error('Error adding book to read later:', error);
    throw error;
  }
};
// Fetch "Read Later" books from the backend
export const fetchReadLaterBooks = async (): Promise<Book[]> => {
  const token = localStorage.getItem('authToken'); // Retrieve the token from local storage
  if (!token) {
    throw new Error('Authentication token is missing.');
  }

  try {
    const response = await axios.get(`${BASE_URL}/api/e/read-later/list`, {
      headers: {
        Authorization: `Bearer ${token}`,  // Include the token in the Authorization header
      },
    });

    console.log('Response Data:', response.data);  // Log the response data to see its structure

    if (Array.isArray(response.data.read_later)) {
      // Map the 'read_later' array to the expected format
      return response.data.read_later.map((book: any) => ({
        id: book.id,
        title: book.title,
        description: book.description,
        cover: `${BASE_URL}${book.thumbnail}`, // Append base URL for cover image
        pdfUrl: `${BASE_URL}${book.file}`, // Append base URL for PDF file
        author: book.uploader_name || 'Unknown Author', // Default to 'Unknown Author' if missing
        year: new Date(book.uploadDate).getFullYear(),
        date: formatDate(book.uploadDate), // Format date
        genre: book.category_name, // Assuming this maps to your genre
      }));
    } else {
      // Handle case when the response is not an array (e.g., object with results key)
      console.error('Expected array but received non-array data:', response.data);
      throw new Error('Failed to fetch books. Expected an array of books.');
    }
  } catch (error) {
    console.error('Error fetching read later books:', error);
    throw error;
  }
};


export const removeBookFromReadLater = async (mediaId: number): Promise<string> => {
  const token = localStorage.getItem('authToken'); // Retrieve the token from local storage
  if (!token) {
    throw new Error('Authentication token is missing.');
  }

  try {
    const response = await axios.delete(
      `${BASE_URL}/api/e/read-later/remove/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,  // Include the token in the Authorization header
          'Content-Type': 'application/json',
        },
        data: { media_id: mediaId }, // Send the media_id in the body of the DELETE request
      }
    );
    return response.data.message; // Assuming the response contains a success message
  } catch (error) {
    console.error('Error removing book from read later:', error);
    throw error;
  }
};









export const createQuiz = async (quizData: { ebook: number; title: string }): Promise<Quiz> => {
  try {
    console.log('Sending quiz data:', quizData);
    const response = await axios.post<Quiz>(`${BASE_URL}/api/e/quizzes/`, quizData, { headers });
    console.log('Server response:', response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error('Server error response:', error.response.data);
      console.error('Status code:', error.response.status);
    }
    console.error('Error creating quiz:', error);
    throw error;
  }
};

export const updateQuiz = async (quizId: number, quizData: Partial<Quiz>): Promise<Quiz> => {
  try {
    const response = await axios.put<Quiz>(`${BASE_URL}/api/e/quizzes/${quizId}/`, quizData, { headers });
    return response.data;
  } catch (error) {
    console.error('Error updating quiz:', error);
    throw error;
  }
};

export const deleteQuiz = async (quizId: number): Promise<void> => {
  try {
    await axios.delete(`${BASE_URL}/api/e/quizzes/${quizId}/`, { headers });
  } catch (error) {
    console.error('Error deleting quiz:', error);
    throw error;
  }
};

export const generateQuiz = async (ebookId: number): Promise<Quiz> => {
  try {
    const response = await axios.post<Quiz>(`${BASE_URL}/api/e/quizzes/generate/`, { ebook_id: ebookId }, { headers });
    return response.data;
  } catch (error) {
    console.error('Error generating quiz:', error);
    throw error;
  }
};

export const fetchQuestions = async (quizId: number): Promise<Question[]> => {
  try {
    const response = await axios.get<Question[]>(`${BASE_URL}/api/e/questions/`, {
      headers,
      params: {
        quiz: quizId,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};
export const createQuestion = async (questionData: Partial<Question>): Promise<Question> => {
  try {
    const response = await axios.post<Question>(`${BASE_URL}/api/e/questions/`, {
      quiz: questionData.quiz,
      question_text: questionData.question_text,
      type: questionData.type,
      options: questionData.type === 'multiple_choice' ? questionData.options : undefined,
      correct_option: questionData.type === 'multiple_choice' ? questionData.correct_option : undefined,
      correct_answer: questionData.type === 'true_false' ? questionData.correct_answer : undefined,
    }, { headers });
    return response.data;
  } catch (error) {
    console.error('Error creating question:', error);
    throw error;
  }
};


export const updateQuestion = async (questionId: number, questionData: Partial<Question>): Promise<Question> => {
  try {
    const response = await axios.put<Question>(`${BASE_URL}/api/e/questions/${questionId}/`, questionData, { headers });
    return response.data;
  } catch (error) {
    console.error('Error updating question:', error);
    throw error;
  }
};

export const deleteQuestion = async (questionId: number): Promise<void> => {
  try {
    await axios.delete(`${BASE_URL}/api/e/questions/${questionId}/`, { headers });
  } catch (error) {
    console.error('Error deleting question:', error);
    throw error;
  }
};







let userUploadedBooks: SimpleBook[] = [];


export const updateEbook = async (updatedBook: SimpleBook): Promise<void> => {
  const index = userUploadedBooks.findIndex((book) => book.id === updatedBook.id);
  if (index !== -1) {
    const existingBook = userUploadedBooks[index];

    // Handle potential file updates
    const updatedCover = typeof updatedBook.cover !== 'string' 
      ? URL.createObjectURL(updatedBook.cover as File) 
      : updatedBook.cover;

    const updatedPdfUrl = updatedBook.pdfUrl && typeof updatedBook.pdfUrl !== 'string' 
      ? URL.createObjectURL(updatedBook.pdfUrl as File) 
      : updatedBook.pdfUrl;

    userUploadedBooks[index] = {
      ...existingBook,
      title: updatedBook.title,
      description: updatedBook.description,
      cover: updatedCover,
      pdfUrl: updatedPdfUrl,
    };
    console.log('Book updated:', userUploadedBooks[index]);
  } else {
    console.error('Book not found:', updatedBook.id);
  }
};








export const fetchUserUploadedBooks = async (): Promise<SimpleBook[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(userUploadedBooks); // Return the user-uploaded SimpleBooks
    }, 500); // Simulate a delay
  });
};



