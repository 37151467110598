// channelServices.tsx
import { toast } from "react-toastify";
import {
  ChannelWithIDAsNumber as Channel,
  Collaborator,
  VideoTitleIndexed as Video,
} from "../types/domain";
import { Playlist } from "../types/domain";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_Django_API_URL;

// Channel Info services
export const getUserChannels = async (): Promise<Channel[]> => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(`${BASE_URL}/api/c/user_channel/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("User channels:", response.data.results);
    return response.data.results; // Assuming the API returns a list of channels
  } catch (error) {
    console.error("Error fetching user channels:", error);
    throw error; // Rethrow the error to handle it in the calling component
  }
};

export const getChannelInfo = async (channelId: number): Promise<Channel> => {
  try {
      console.log('Channel ID:', channelId);
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${BASE_URL}/api/c/channel/${channelId}/`, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });
      console.log('Channel info:', response.data);
      return response.data;
  } catch (error) {
    console.error("Error fetching channel info:", error);
    throw error;
  }
};

export const updateChannelInfo = async (
  channelId: number,
  name: string,
  description: string,
  icon: File | string | null,
  cover: File | string | null
): Promise<Channel> => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);

  if (icon instanceof File) {
    formData.append("icon", icon);
  }

  if (cover instanceof File) {
    formData.append("cover", cover);
  }

  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.patch(
      `${BASE_URL}/api/c/channel/${channelId}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Channel updated:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating channel:", error);
    throw error;
  }
};

export const createChannel = async (
  name: string,
  description: string,
  icon: File | string | null,
  cover: File | string | null
): Promise<Channel> => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);

  if (icon instanceof File) {
    formData.append("icon", icon);
  }

  if (cover instanceof File) {
    formData.append("cover", cover);
  }

  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.post(`${BASE_URL}/api/c/channel/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Channel created:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creating channel:", error);
    throw error;
  }
};
// Collaborator services
export const inviteCollaborator = async (
  channelId: number,
  email: string,
  role: string
): Promise<void> => {
  try {
    const token = localStorage.getItem("authToken");
    await axios.post(
      `${BASE_URL}/api/c/channel_membership/invite/`,
      {
        channel: channelId,
        invitee_email: email,
        role: role === "Owner" ? "2" : "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error inviting collaborator:", error);
    throw error;
  }
};

export const getInvitationDetails = async (token: string): Promise<any> => {
  try {
    const authToken = localStorage.getItem("authToken");
    const response = await axios.get(
      `${BASE_URL}/api/c/channel_membership/accept-invitation/${token}/`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching invitation details:", error);
    throw error;
  }
};

export const acceptInvitation = async (token: string): Promise<void> => {
  try {
    const authToken = localStorage.getItem("authToken");
    await axios.post(
      `${BASE_URL}/api/c/channel_membership/accept-invitation/${token}/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  } catch (error) {
    console.error("Error accepting invitation:", error);
    throw error;
  }
};

export const getCollaborators = async (
  channelId: number
): Promise<Collaborator[]> => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(
      `${BASE_URL}/api/c/channel_membership/channel/${channelId}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.map((member: any) => ({
      email: member.user.email,
      role: member.role === "2" ? "Owner" : "Editor",
    }));
  } catch (error) {
    console.error("Error fetching collaborators:", error);
    throw error;
  }
};

export const addCollaborator = async (
  channelId: number,
  collaborator: Omit<Collaborator, "id">
): Promise<void> => {
  try {
    const token = localStorage.getItem("authToken");
    await axios.post(
      `${BASE_URL}/api/c/channel_membership/`,
      {
        channelID: channelId,
        email: collaborator.email,
        role: collaborator.role === "Owner" ? "2" : "1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error adding collaborator:", error);
    throw error;
  }
};

export const removeCollaborator = async (
  channelId: number,
  email: string
): Promise<void> => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(
      `${BASE_URL}/api/c/channel_membership/channel/${channelId}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const membershipToDelete = response.data.find(
      (member: any) => member.user.email === email
    );
    if (membershipToDelete) {
      await axios.delete(
        `${BASE_URL}/api/c/channel_membership/${membershipToDelete.id}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      throw new Error("Collaborator not found");
    }
  } catch (error) {
    console.error("Error removing collaborator:", error);
    throw error;
  }
};

export const updateCollaboratorRole = async (
  channelId: number,
  email: string,
  newRole: string
): Promise<void> => {
  try {
    const token = localStorage.getItem("authToken");
    const response = await axios.get(
      `${BASE_URL}/api/c/channel_membership/channel/${channelId}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Collaborators:", response.data);
    const membershipToUpdate = response.data.find(
      (member: any) => member.user.email === email
    );
    console.log("membership:", membershipToUpdate.id);
    if (membershipToUpdate) {
      await axios.patch(
        `${BASE_URL}/api/c/channel_membership/${membershipToUpdate.id}/`,
        {
          role: newRole === "Owner" ? "2" : "1",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      throw new Error("Collaborator not found");
    }
  } catch (error) {
    console.log(newRole === "Owner" ? "2" : "1");
    console.error("Error updating collaborator role:", error);
    throw error;
  }
};

// Playlist services
let playlists: Playlist[] = [];
let videos: Video[] = [
  { id: "1", title: "Sample Video 1" },
  { id: "2", title: "Sample Video 2" },
];
export const getPlaylists = async (channelID: number): Promise<Playlist[]> => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await fetch(
      `${BASE_URL}/api/p/playlist/channel/${channelID}/collections/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      toast.error("Not all the features can be loaded because you are not authenticated");
      return [];
    }
    const data = await response.json();

    return data.results;
  } catch (error) {
    console.error("Error fetching playlists:", error);
    throw error; // Re-throw the error to handle it further up if needed
  }
};

export const createCollection = async (
  name: string,
  channelId: number,
  description: string
): Promise<void> => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/p/playlist/`,
      {
        name: name, // Playlist name
        channel: channelId, // Channel ID
        type: 1, // Assuming 'type' is 1 as per your provided data
        description: description, // Description for the playlist
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const newPlaylist = response.data; // Capture the created playlist data from the API response
    playlists.push(newPlaylist); // Push the new playlist to the local playlists array
    console.log("Playlist created successfully:", newPlaylist);
  } catch (error) {
    console.error("Failed to create playlist:", error);
    throw error; // Re-throw the error to handle it further up if needed
  }
};

export const updateCollection = async (id: number,channel: number, name: string, description: string): Promise<void> => {
  const token = localStorage.getItem('authToken');
  
  try {
    const response = await axios.put(`${BASE_URL}/api/p/playlist/${id}/update/`, {
      name: name,
      description: description,
      type: 1,
      channel: channel
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    console.log('Collection updated successfully:', response.data);
  } catch (error) {
    console.error('Failed to update collection:', error);
    throw error; // Re-throw the error to handle it further up if needed
  }
};

export const addVideoToPlaylist = async (
  playlistId: string,
  videoId: string
): Promise<void> => {
  const playlist = playlists.find((p) => p.id === playlistId);
  const video = videos.find((v) => v.id === videoId);
  if (playlist && video) {
    // playlist.videos.push(video);
    // playlist. = "";
  }
};

export const removeVideoFromPlaylist = async (
  playlistId: string,
  videoId: string
): Promise<void> => {
  const playlist = playlists.find((p) => p.id === playlistId);
  if (playlist) {
    playlist.videos = playlist.videos.filter((v) => v.id !== videoId);
  }
};

export const getVideos = async (): Promise<Video[]> => {
  return videos;
};

// Notification services
let notifications = {
  emailNotification: true,
  views: 0,
  viewThreshold: 100,
  viewNotification: false,
  comments: 0,
  commentThreshold: 50,
  commentNotification: false,
  likes: 0,
  likeThreshold: 200,
  likeNotification: false,
};
export const getNotifications = async () => {
  return notifications;
};

export const updateNotificationSettings = async (
  newSettings: Partial<typeof notifications>
) => {
  notifications = { ...notifications, ...newSettings };
};
