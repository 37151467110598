import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { 
  ArrowLeft, Clock, MapPin, BookOpen, Globe, Users, Tags,
  Info, Play, X, Maximize2, Minimize2, Eye
} from 'lucide-react';

// Services and Utils
import { withoutAuthfetchVideoDataByID } from '../services/uploadVideo';
import { getYoutubeThumbnail } from '../utils/helpers/getYoutubeThumbnail';

// Layouts & Components
import AppBar from '../layouts/GeneralAppBar/AppBar';
import Footer from '../components/GeneralAppBar/footer';

const VideoDetailsPage: React.FC = () => {

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const [videoData, setVideoData] = useState<any>(null);

  const [loading, setLoading] = useState(true);

  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const [isImageExpanded, setIsImageExpanded] = useState(false);

  // Fetch data by video ID
  useEffect(() => {
    const loadVideoData = async () => {
      if (!id) return;
      try {
        setLoading(true);
        const data = await withoutAuthfetchVideoDataByID(id);
        setVideoData(data);
      } catch (error) {
        console.error('Error fetching video data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadVideoData();
  }, [id]);

  // Close expanded thumbnail on Escape
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsImageExpanded(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  // Loading state
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-green-50 to-green-100">
        <div className="animate-pulse space-y-4 p-8 bg-white rounded-xl shadow-lg">
          <div className="h-4 bg-green-200 rounded w-32"></div>
          <div className="h-8 bg-green-200 rounded w-64"></div>
          <div className="h-4 bg-green-200 rounded w-48"></div>
        </div>
      </div>
    );
  }

  // Video not found state
  if (!videoData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-green-50">
        <div className="text-3xl text-black-700">Video Not Found</div>
      </div>
    );
  }

  // Navigate to watch video
  const handleWatchVideo = () => {
    navigate(`/videos/watch/${id}`);
  };

  // Data table configuration
  const tableData = [
    // ----- BASIC INFO -----
    {
      label: 'Title',
      value: videoData.title,
      category: 'Basic Info'
    },
    {
      label: 'Interview Date',
      value: new Date(videoData.interviewDate).toLocaleDateString('en-US', {
        year: 'numeric', month: 'long', day: 'numeric'
      }),
      icon: <Clock size={16} />,
      category: 'Basic Info'
    },
    {
      label: 'Location',
      value: videoData.location,
      icon: <MapPin size={16} />,
      category: 'Basic Info'
    },
    {
      label: 'Language',
      value: videoData.language,
      icon: <Globe size={16} />,
      category: 'Basic Info'
    },
    {
      label: 'Table of Content',
      value: (
        <ul className="list-disc list-inside text-black-800">
          {videoData.segments?.map((seg: any, index: number) => (
            <li key={index}>
              Segment {index + 1}: {seg.segment}
            </li>
          ))}
        </ul>
      ),
      category: 'Basic Info',
    },
    {
      label: 'Uploaded Date',
      value: new Date(videoData.uploadDate).toLocaleDateString('en-US', {
        year: 'numeric', month: 'long', day: 'numeric'
      }),
      icon: <Clock size={16} />,
      category: 'Basic Info'
    },

    // ----- PEOPLE & ENGAGEMENT -----
    {
      label: 'Participants',
      value: videoData.participants?.join(', '),
      icon: <Users size={16} />,
      category: 'People & Engagement'
    },
    {
      label: 'Important Persons',
      value: videoData.important_persons?.join(', '),
      icon: <Users size={16} />,
      category: 'People & Engagement'
    },
    {
      label: 'Acknowledgement',
      value: videoData.acknowledgment,
      icon: <Info size={16} />,
      category: 'People & Engagement'
    },
    {
      label: 'Views',
      value: videoData.views,
      icon: <Eye size={16} />,
      category: 'People & Engagement'
    },

    // ----- CLASSIFICATION -----
    {
      label: 'Category',
      value: videoData.category,
      icon: <Tags size={16} />,
      category: 'Classification'
    },
    {
      label: 'Type',
      value: videoData.type,
      category: 'Classification'
    },
    {
      label: 'Topics',
      value: (
        <ul className="list-disc list-inside text-black-800">
          {videoData.topics?.map((topic: string, index: number) => (
            <li key={index}>{topic}</li>
          ))}
        </ul>
      ),
      icon: <Tags size={16} />,
      category: 'Classification'
    },
    {
      label: 'Collection',
      value: videoData.collectionName,
      icon: <BookOpen size={16} />,
      category: 'Classification'
    },
  ];

  // Define your categories in the desired display order
  const categories = [
    'Basic Info',
    'People & Engagement',
    'Classification',
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-green-100">
      <AppBar toggleSidebar={() => {}} setSearchQuery={() => {}} searchQuery="" />

      <div className="max-w-7xl mx-auto pt-32 pb-16">
        <button
          onClick={() => navigate('/home')}
          className="mb-6 flex items-center gap-2 text-black-600 hover:text-black transition-colors"
        >
          <ArrowLeft size={18} />
          Back to Gallery of Videos
        </button>

        <div className="bg-white rounded-xl shadow-lg p-6 border border-green-200">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* LEFT SECTION: Table Data */}
            <div className="lg:col-span-2">
              <div className="space-y-6">
              {categories.map((category) => (
  <div key={category}>
    <h3 className="text-lg font-semibold text-black-800 mb-3 px-4 flex items-center gap-2">
      {category}
      <div className="h-1 w-8 bg-green-500 rounded-full"></div>
    </h3>

    {category === "People & Engagement" ? (
      <ul className="bg-white rounded-lg overflow-hidden border border-green-200 p-4 space-y-4">
        {tableData
          .filter((item) => item.category === category)
          .map((item, index) => (
            <li
              key={index}
              className="flex items-start gap-3 border-b border-green-100 pb-4 last:border-none"
            >
              <div className="p-2 rounded-lg bg-green-100 text-black-600 flex-shrink-0">
                {item.icon}
              </div>
              <div>
                <span className="font-medium text-black-700">{item.label}:</span>{" "}
                <span className="text-black-800">{item.value || "N/A"}</span>
              </div>
            </li>
          ))}
      </ul>
    ) : (
      <div className="bg-white rounded-lg overflow-hidden border border-green-200">
        <table className="w-full">
          <tbody>
            {tableData
              .filter((item) => item.category === category)
              .map((item, index) => (
                <tr
                  key={index}
                  className="hover:bg-green-50 transition-colors duration-200"
                >
                  <td className="py-4 px-4 font-medium text-black-700 w-1/3 flex items-center gap-3 border-b border-green-100">
                    {item.icon && (
                      <span className="p-2 rounded-lg bg-green-100 text-black-600">
                        {item.icon}
                      </span>
                    )}
                    {item.label}
                  </td>
                  <td className="py-4 px-4 text-black-800 border-b border-green-100">
                    {item.value || "N/A"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
))}
                <div className="mt-6">
                  <button
                    onClick={handleWatchVideo}
                    className="w-full flex items-center justify-center gap-3 px-6 py-4 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors group"
                  >
                    <Play size={20} className="group-hover:scale-110 transition-transform" />
                    Watch Video
                  </button>
                </div>
              </div>
            </div>

            {/* RIGHT SECTION: Thumbnail & Description */}
            <div className="lg:col-span-1">
              <div className="relative rounded-lg overflow-hidden shadow-md group">
                <button
                  onClick={() => setIsImageExpanded(true)}
                  className="absolute top-2 right-2 p-2 bg-black/50 rounded-full text-white opacity-0 group-hover:opacity-100 transition-opacity hover:bg-black/70"
                >
                  <Maximize2 size={20} />
                </button>
                <img
                  src={getYoutubeThumbnail(videoData.url)}
                  alt="Video thumbnail"
                  className="w-full h-auto"
                />
              </div>

              {videoData.description && (
                <div className="mt-6 bg-green-50 rounded-lg p-4 border border-green-200">
                  <h3 className="font-medium text-black-800 mb-2">Description</h3>
                  <p className="text-black-700 leading-relaxed">
                    {videoData.description}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Fullscreen Thumbnail Modal */}
        {isImageExpanded && (
          <div
            className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center p-4"
            onClick={() => setIsImageExpanded(false)}
          >
            <div
              className="relative max-w-7xl w-full mx-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() => setIsImageExpanded(false)}
                className="absolute top-4 right-4 p-2 bg-black/50 rounded-full text-white hover:bg-black/70 transition-colors"
              >
                <Minimize2 size={24} />
              </button>
              <img
                src={getYoutubeThumbnail(videoData.url)}
                alt="Video thumbnail"
                className="w-full h-auto max-h-screen object-contain rounded-lg"
              />
            </div>
          </div>
        )}

        {/* Video Modal */}
        {isVideoModalOpen && (
          <div className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center p-8">
            <button
              className="absolute top-4 right-4 text-white hover:text-black-300 transition-colors"
              onClick={() => setIsVideoModalOpen(false)}
            >
              <X size={24} />
            </button>
            <div className="w-full max-w-4xl">
              <ReactPlayer
                url={videoData.url}
                width="100%"
                height="100%"
                controls
              />
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default VideoDetailsPage;
