import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaHome, FaGlobe, FaUser, FaSignOutAlt, FaUserCircle, FaHistory, FaList, FaVideo, FaClock, FaThumbsUp, FaChartBar } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { NavLinkProps, SidebarProps } from '../../types/layouts';
import CustomNavLink from './CustomNavLink';
import i18n from '../../i18n';
import { LanguageContext } from '../../App';


const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, className }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/signin');
  };

  const [side, setSide] = React.useState('left-0');


  const {language, setLanguage}:any = useContext(LanguageContext);
  
  return (
    <div
      className={`group flex flex-col h-full  ${language === 'ar' ? 'right-0' : 'left-0'}  bg-white pb-16 fixed top-16  justify-between w-16 hover:w-64  text-black transition-width duration-200 shadow-2xl ${className} z-40`}
    >
      <nav className="flex flex-col mt-4">
        <CustomNavLink to="/videos/home" icon={FaHome} label="Home" isCollapsed={isCollapsed} />
        <CustomNavLink to="/videos/your-channel" icon={FaUserCircle} label="Your Channel" isCollapsed={isCollapsed} />
        {/* <CustomNavLink to="/videos/history" icon={FaHistory} label="History" isCollapsed={isCollapsed} /> */}
        <CustomNavLink to="/videos/playlists" icon={FaList} label="Playlists" isCollapsed={isCollapsed} />
        <CustomNavLink to="/videos/your-videos" icon={FaVideo} label="Your Videos" isCollapsed={isCollapsed} />
        <CustomNavLink to="/videos/watch-later" icon={FaClock} label="Watch Later" isCollapsed={isCollapsed} />
        {/* <CustomNavLink to="/videos/analytics" icon={FaChartBar} label="Analytics" isCollapsed={isCollapsed} /> */}
      </nav>
      <button
        className="flex items-center p-4 w-full h-12 hover:bg-gray-200 transition-colors duration-1000"
        onClick={handleLogout}
      >
        <FaSignOutAlt className="mr-4" />
        <div
          className={`w-0 opacity-0 group-hover:block text-nowrap items-center group-hover:opacity-100 transition-opacity duration-100 ${
            isCollapsed ? 'hidden' : 'block'
          }`}
        >
          {'Log Out'}
        </div>
      </button>
    </div>
  );
};

export default Sidebar;
