import axios from 'axios';
import { Video, Person,Segment } from '../types/forms/video';
import { channel } from 'diagnostics_channel';

const BASE_URL = process.env.REACT_APP_Django_API_URL || 'http://127.0.0.1:8000';

export const withoutAuthfetchVideoDataByID = async (
  id: string | undefined
): Promise<any | null> => {
  try {
    // Fetch main video data
    const response = await fetch(`${BASE_URL}/api/v/video/${id}/`);

    if (response.ok) {
      const apiResponse = await response.json();
      const videoData = {
        title: apiResponse.media_details.title,
        url: apiResponse.media_details.video_url,
        description: apiResponse.media_details.description,
        views: apiResponse.media_details.views,
        likes: apiResponse.media_details.likes,
        category: apiResponse.media_details?.category_name || 'Unknown Category',
        type: apiResponse.media_details?.type || 'Unknown Type',
        uploadDate: apiResponse.media_details?.uploadDate || 'Unknown Upload Date',
        interviewDate: apiResponse.interviewDate,
        originalLanguage: apiResponse.media_details.originalLanguage,
        language: apiResponse.language,
        location: apiResponse.monument?.city?.city_name,
        collectionName: apiResponse.collection_name || 'N/A',
        segments: [],
        participants: apiResponse.participants.map((p: any) => `${p.firstName} ${p.lastName}`),
        topics: apiResponse.topics,
        important_persons: apiResponse.important_persons,
        acknowledgment: apiResponse.media_details.acknowledgement,
        fullTranscript: apiResponse.fullTranscript || 'No transcript available',
      };

      // Fetch segment data
      const segmentResponse = await fetch(`${BASE_URL}/api/v/combined/get_segment_transcript/${id}/`);

      if (segmentResponse.ok) {
        const segmentData = await segmentResponse.json();
        const segments = segmentData.map((segment: any) => ({
          segment: segment.segment.description,
        }));

        // Combine video data with segments
        return { ...videoData, segments };
      }
    }
  } catch (error) {
    console.error('Failed to fetch video data', error);
  }

  return null;
};

export const fetchVideoDataByID = async (
  id: string | undefined
): Promise<any | null> => {
  const response = await fetch(`${BASE_URL}/api/v/video/${id}/`,);
  if (response.ok) {
    const videoData = await response.json();
    return videoData;
  }

  try {
    const response = await fetch(`${BASE_URL}/api/v/video/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
      },
    });

    if (response.ok) {
      const videoData = await response.json();
      return videoData;
    }
  } catch (error) {
    console.error("Failed to fetch video data", error);
  }

  return null;
};

export const fetchTranscriptsByID = async (id: string): Promise<any> => {
  const token = localStorage.getItem("authToken");
  const response = await fetch(
    `${BASE_URL}/api/v/combined/get_segment_transcript/${id}/`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.ok) {
    const Data = await response.json();
    const combinedSegmentTranscripts: Segment[] = Data.map(
      (item: any) => {
        return {
          segment: item.segment,
          transcripts: item.transcripts,
        };
      }
    );

    const languages = combinedSegmentTranscripts.map((CST) => {
      return CST.transcripts.map((T) => T.language);
    });

    const availbleLanguages = Array.from(new Set(languages.flat())).map(
      (language) => {
        return language;
      }
    );

    return { combinedSegmentTranscripts, availbleLanguages };
  }

  return {
    combinedSegmentTranscripts: [],
    availbleLanguages: [],
  };
};
export const fetchCollections = async () => {
  try {
    const channelID = localStorage.getItem("channelId");
    const token = localStorage.getItem('authToken');
    const response = await axios.get(`${BASE_URL}/api/p/playlist/channel/${channelID}/collections`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log("collections",response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching collections:', error);
    throw error;
  }
};

export const fetchVideos = async (): Promise<Video[]> => {
  try {
    const token = localStorage.getItem('authToken');
    const response = await axios.get(`${BASE_URL}/api/v/video/`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.results.map((video: any) => ({
      id: video.id,
      title: video.media_details.title || 'No title',
      description: video.media_details.description || 'No description',
      views: video.media_details.views || 0,
      likes: video.media_details.likes || 0,
      mediaFormat: video.media_details.type || 'Unknown',
      category: video.media_details.category_name || 'Uncategorized',
      interviewDate: video.interviewDate || 'N/A',
      url: video.videoURL || 'https://via.placeholder.com/100', // Default URL
    }));
  } catch (error) {
    console.error('Error fetching videos:', error);
    throw error;
  }
};
export const fetchChannelVideos = async (channelId: string): Promise<Video[]> => {
  try {
    const token = localStorage.getItem('authToken');
    const response = await axios.get(`${BASE_URL}/api/v/video/get_channel_videos/?channel_id=${channelId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.results.map((video: any) => ({
      id: video.id,
      title: video.media_details.title || 'No title',
      description: video.media_details.description || 'No description',
      views: video.media_details.views || 0,
      likes: video.media_details.likes || 0,
      mediaFormat: video.media_details.type || 'Unknown',
      category: video.media_details.category_name || 'Uncategorized',
      interviewDate: video.interviewDate || 'N/A',
      url: video.videoURL || 'https://via.placeholder.com/100', // Default URL
    }));
  } catch (error) {
    console.error('Error fetching videos:', error);
    throw error;
  }
}
// Fetch a single video by ID
export const fetchVideo = async (videoId: number): Promise<Video> => {
  try {
    const token = localStorage.getItem('authToken');
    const response = await axios.get(`${BASE_URL}/api/v/video/${videoId}/`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return {
      id: response.data.id,
      title: response.data.media_details.title || 'No title',
      description: response.data.media_details.description || 'No description',
      views: response.data.media_details.views || 0,
      likes: response.data.media_details.likes || 0,
      mediaFormat: response.data.media_details.type || 'Unknown',
      category: response.data.media_details.categoryID.name || 'Uncategorized',
      interviewDate: response.data.interviewDate || 'N/A',
      url: response.data.videoURL || 'https://via.placeholder.com/100', // Default URL
      collectionName: response.data.collectionName || 'Default Collection',
      originalLanguage: response.data.media_details.originalLanguage || 'en',
      segments: response.data.segments || [],
      location: response.data.location || 'Unknown Location',
      acknowledgment: response.data.acknowledgment || '',
      fullTranscript: response.data.transcript || '',
      interviewees: response.data.interviewees || [],
      interviewers: response.data.interviewers || [],
      comments: response.data.comments || 0,
      visibility: response.data.visibility.toString(),
      restriction: response.data.restriction.toString(),
      media_details: {
        title: response.data.media_details.title || 'No title',
        uploaderID: response.data.media_details.uploaderID,
        description: response.data.media_details.description || 'No description',
        uploadDate: response.data.media_details.uploadDate || '',
        type: response.data.media_details.type || '1',
        originalLanguage: response.data.media_details.originalLanguage || 'en',
        categoryID: { name: response.data.media_details.categoryID.name || 'Default Category' },
        channelID: response.data.media_details.channelID || '5',
      },
      videoURL: response.data.videoURL || '',
      size: response.data.size || 0,
      participants: response.data.participants || [],
      topics: response.data.topics || [],
      important_persons: response.data.important_persons || [],
      transcripts: response.data.transcripts || [{
        title: '',
        content: '',
        transcription: '',
        transcriptionLanguage: response.data.media_details.originalLanguage || ''
      }],

    };
  } catch (error) {
    console.error('Error fetching video:', error);
    throw error;
  }
};

const visibilityMap: { [key: string]: string } = {
  'public': '1',
  'private': '0',
  'unlisted': '2'
};

const restrictionMap: { [key: string]: string } = {
  'above': '1',
  'below': '0'
};
export const updateMedia = async (id: string, formData: Video) => {
const formDataToSend = new FormData();

formDataToSend.append('title', formData.media_details.title);
// description
formDataToSend.append('description', formData.media_details.description);
// category
formDataToSend.append('categoryID', formData.media_details.categoryID.name);
// type
formDataToSend.append('type', formData.media_details.type);
// acknowledgement
console.log("acknowledgment",formData.acknowledgment)
formDataToSend.append('acknowledgement', formData.acknowledgment);
// original language
formDataToSend.append('originalLanguage', formData.originalLanguage);
// channel ID
console.log("channelID",formData.media_details);
formDataToSend.append('channelID', formData.media_details.channelID.toString());
// uploader ID
formDataToSend.append('uploaderID', formData.media_details.uploaderID.toString());

// send put request
const token = localStorage.getItem('authToken');
if (!token) {
  console.error('No auth token found in localStorage.');
  return false;
}

try {
  const response = await axios.put(`${BASE_URL}/api/m/media/${id}/`, formDataToSend, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  console.log(response.data);
  return response.data;
} catch (error) {
  if (axios.isAxiosError(error)) {
    console.error('Error updating media:', error.response?.data || error.message);
  } else {
    console.error('Unexpected error:', error);
  }
  throw error;
}
}
/*export const updateVideo = async (id: string, formData: Video) => {
  const channelID = localStorage.getItem("channelId");
  const formDataToSend = new FormData();
  console.log("Channel ID:", channelID);

  // Prepare video data
  const videoData = {
    visibility: visibilityMap[formData.visibility] || '2',
    interviewDate: formData.interviewDate || '2024-07-10',
    mediaID: id,
    videoURL: formData.videoURL || '',
    acknowledgement: formData.acknowledgment || '',
    monument: {
      monument_name: formData.location.monument_name || '',
      city: {
        city_name: formData.location.city_name || ''
      }
    },
    topics: formData.topics.length ? formData.topics : ["Default Topic"],
    important_persons: formData.important_persons.length ? formData.important_persons : ["Unknown"]
  };
  // print title
  console.log("Title:", formData.media_details.title);

  // Append video data as a JSON string
  formDataToSend.append('video', JSON.stringify(videoData));
  formDataToSend.append('important_persons', JSON.stringify(videoData.important_persons));
  formDataToSend.append('topics', JSON.stringify(videoData.topics));
  formDataToSend.append('mediaID', JSON.stringify(parseInt(videoData.mediaID)));
  formDataToSend.append('title', JSON.stringify(formData.media_details.title));
  console.log("Video Data:", videoData.mediaID);
  
  formDataToSend.append('videoURL', videoData.videoURL);
  console.log("Video URL:", videoData.videoURL);
  // Prepare and append participants data
  const participants = [
    ...formData.interviewees.map((interviewee: Person) => ({
      firstName: interviewee.firstName || '',
      lastName: interviewee.lastName || '',
      phoneNumber: interviewee.phoneNumber || '',
      role: 2
    })),
    ...formData.interviewers.map((interviewer: Person) => ({
      firstName: interviewer.firstName || '',
      lastName: interviewer.lastName || '',
      phoneNumber: interviewer.phoneNumber || '',
      role: 1
    }))
  ];
  formDataToSend.append('participants', JSON.stringify(participants));
  console.log("transcript ibork",formData.transcript.transcription)
 
  formDataToSend.append('fullTranscript', formData.fullTranscript);

  // Prepare and append segments data
  const segmentsData = formData.segments.map((segment, index) => ({
    segmentNumber: index + 1,
    startTime: segment.startTime,
    endTime: segment.endTime,
    description: segment.description,
    transcript: {
      transcription: segment.transcripts[0]?.transcript || "",
      transcriptionLanguage: segment.transcripts[0]?.language || ""
    }
  }));
  formDataToSend.append('segments', JSON.stringify(segmentsData));

  // Append file uploads if they exist
  if (formData.location.monument_image) {
    formDataToSend.append('monument_image', formData.location.monument_image);
  }
  if (formData.location.city_image) {
    formDataToSend.append('city_image', formData.location.city_image);
  }
  console.log("update videos: ",formDataToSend)
  const token = localStorage.getItem('authToken');
  if (!token) {
    console.error('No auth token found in localStorage.');
    return false;
  }

  try {
    const response = await axios.put(`${BASE_URL}/api/v/video/${id}/update-complex-video/`, formDataToSend, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error updating video:', error.response?.data || error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};*/
export const uploadVideo = async (formData: Video) => {
  console.log('Form data:', formData);
  const channelID = localStorage.getItem("channelId");
  const formDataToSend = new FormData();
  console.log("channelID:", channelID);

  // Prepare video data with latitude and longitude
  const videoData = {
    visibility: visibilityMap[formData.visibility] || '2',
    interviewDate: formData.interviewDate || '2024-07-10',
    mediaID: {
      title: formData.media_details.title || formData.title,
      description: formData.media_details.description || formData.description,
      type: formData.media_details.type || '1',
      originalLanguage: formData.media_details.originalLanguage || formData.originalLanguage,
      categoryID: formData.category === 'Other' ? { name: formData.customCategory || 'Default Category' } : formData.category,
      channelID: channelID,
      uploaderID: formData.media_details.uploaderID,
      uploadDate: formData.media_details.uploadDate || new Date().toISOString(),
    },
    videoURL: formData.videoURL || '',
    acknowledgement: formData.acknowledgment || '',
    monument: {
      city: {
        city_name: formData.location.city_name || ''
      },
      latitude: formData.location.latitude || null,    // Added latitude
      longitude: formData.location.longitude || null,  // Added longitude
    },
    topics: formData.topics || [],
    important_persons: formData.important_persons || [],
  };

  // Append video data as a JSON string
  formDataToSend.append('video', JSON.stringify(videoData));

  // Prepare and append participants data
  const participants = [
    ...formData.interviewees.map((interviewee: Person) => ({
      firstName: interviewee.firstName || '',
      lastName: interviewee.lastName || '',
      phoneNumber: interviewee.phoneNumber || '',
      role: 2
    })),
    ...formData.interviewers.map((interviewer: Person) => ({
      firstName: interviewer.firstName || '',
      lastName: interviewer.lastName || '',
      phoneNumber: interviewer.phoneNumber || '',
      role: 1
    }))
  ];
  formDataToSend.append('participants', JSON.stringify(participants));

  // Prepare and append transcript data
  const transcriptData = formData.transcripts.map((t) => ({
    transcription: t.transcription,
    transcriptionLanguage: t.transcriptionLanguage || "",
  }));
  formDataToSend.append('transcripts', JSON.stringify(transcriptData));

  // Prepare and append segments data
  const segmentsData = formData.segments.map((segment, index) => ({
    segmentNumber: index + 1,
    startTime: segment.startTime,
    endTime: segment.endTime,
    description: segment.description,
    transcript: {
      transcription: segment.transcripts[0]?.transcript || "",
      transcriptionLanguage: segment.transcripts[0]?.language || ""
    }
  }));
  formDataToSend.append('segments', JSON.stringify(segmentsData));

  // Append file uploads if they exist
  if (formData.collectionName) {
    formDataToSend.append('playlist', formData.collectionName);
  }

  const token = localStorage.getItem('authToken');
  if (!token) {
    console.error('No auth token found in localStorage.');
    return false;
  }

  try {
    console.log("FormData to send:", formDataToSend);
    const response = await axios.post(`${BASE_URL}/api/v/video/create-complex-video/`, formDataToSend, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log("Upload Video Response:", response.data.video_id);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error uploading video:', error.response?.data || error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};
export const updateVideo = async (id: string, formData: Video) => {
  const channelID = localStorage.getItem("channelId");
  const formDataToSend = new FormData();
  console.log("channelID:", channelID);
  // print the lat and long
  // Prepare video data with latitude and longitude
  const videoData = {
    visibility: visibilityMap[formData.visibility] || '2',
    interviewDate: formData.interviewDate || '2024-07-10',
    mediaID: {
      title: formData.media_details.title || formData.title,
      description: formData.media_details.description || formData.description,
      type: formData.media_details.type || '1',
      originalLanguage: formData.media_details.originalLanguage || formData.originalLanguage,
      categoryID: formData.category === 'Other' ? { name: formData.customCategory || 'Default Category' } : formData.category,
      channelID: channelID,
      uploaderID: formData.media_details.uploaderID,
      uploadDate: formData.media_details.uploadDate || new Date().toISOString(),
    },
    videoURL: formData.videoURL || '',
    acknowledgement: formData.acknowledgment || '',
    monument: {
      city: {
        city_name: formData.location.city_name || ''
      },
      latitude: formData.location.latitude || null,    // Added latitude
      longitude: formData.location.longitude || null,  // Added longitude
    },
    topics: formData.topics || [],
    important_persons: formData.important_persons || [],
  };

  // Append video data as a JSON string
  formDataToSend.append('video', JSON.stringify(videoData));

  // Prepare and append participants data
  const participants = [
    ...formData.interviewees.map((interviewee: Person) => ({
      firstName: interviewee.firstName || '',
      lastName: interviewee.lastName || '',
      phoneNumber: interviewee.phoneNumber || '',
      role: 2
    })),
    ...formData.interviewers.map((interviewer: Person) => ({
      firstName: interviewer.firstName || '',
      lastName: interviewer.lastName || '',
      phoneNumber: interviewer.phoneNumber || '',
      role: 1
    }))
  ];
  formDataToSend.append('participants', JSON.stringify(participants));

  // Prepare and append transcript data
  const transcriptData = formData.transcripts.map((t) => ({
    transcription: t.transcription,
    transcriptionLanguage: t.transcriptionLanguage || "",
  }));
  formDataToSend.append('transcripts', JSON.stringify(transcriptData));

  // Prepare and append segments data
  const segmentsData = formData.segments.map((segment, index) => ({
    segmentNumber: index + 1,
    startTime: segment.startTime,
    endTime: segment.endTime,
    description: segment.description,
    transcript: {
      transcription: segment.transcripts[0]?.transcript || "",
      transcriptionLanguage: segment.transcripts[0]?.language || ""
    }
  }));
  formDataToSend.append('segments', JSON.stringify(segmentsData));

  // Append file uploads if they exist
  if (formData.collectionName) {
    formDataToSend.append('playlist', formData.collectionName);
  }

  const token = localStorage.getItem('authToken');
  if (!token) {
    console.error('No auth token found in localStorage.');
    return false;
  }

  try {
    console.log("FormData to send:", formDataToSend);
    const response = await axios.put(
      `${BASE_URL}/api/v/video/${id}/update-complex-video/`,
      formDataToSend,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    console.log("Update Video Response:", response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error updating video:', error.response?.data || error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};

export const uploadSegment = async (videoId: number, segmentsData: Segment[]) => {
  console.log(segmentsData)
  const data = {
    video_id: videoId,
    segments: segmentsData.map((segment, index) => ({
      segmentNumber: index + 1,
      title: segment.title,
      startTime: segment.startTime,
      endTime: segment.endTime,
      description: segment.description,
    })),
    transcripts: segmentsData.flatMap((segment, index) =>
      segment.transcripts.map((transcript, idx) => ({
        segmentNumber: index + 1,
        title: ` ${segment.title}`,
        content: transcript.transcript,
        transcription: transcript.transcript,
        transcriptionLanguage: transcript.language,
      }))
    )
  };
  console.log('Data to be sent:', data);
  
  const token = localStorage.getItem('authToken');
  if (!token) {
    console.error('No auth token found in localStorage.');
    return false;
  }

  try {
      const response = await axios.post(`${BASE_URL}/api/v/combined/create-segments-and-transcripts/`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log('Segment upload response:', response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Error uploading segments:', error.response?.data || error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};


