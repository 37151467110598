import React, { useState, useEffect } from 'react';
import { addEbook, fetchCategories, fetchChannels } from '../../services/ebooks';
import axios from 'axios';
interface EbookFormValues {
  title: string;
  description: string;
  type: string;
  acknowledgement: string;
  originalLanguage: string;
  file: File | null;
  drmProtected: boolean;
  thumbnail: File | null;
  categoryID: string;
  channelID: string;
}

const TYPE_CHOICES = [
  { value: '1', label: 'Video' },
  { value: '2', label: 'Audio' },
  { value: '3', label: 'Other' },
  { value: '4', label: 'Ebook' },
];

const AddEbookForm: React.FC = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [channels, setChannels] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<EbookFormValues>({
    title: '',
    description: '',
    type: '4',
    acknowledgement: '',
    originalLanguage: '',
    file: null,
    drmProtected: false,
    thumbnail: null,
    channelID: '',
    categoryID: ''
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const loadDependencies = async () => {
      try {
        const fetchedCategories = await fetchCategories();
        const fetchedChannels = await fetchChannels();
        setCategories(fetchedCategories.results);
        setChannels(fetchedChannels.results);
      } catch (error) {
        console.error("Failed to fetch dependencies:", error);
      }
    };
    loadDependencies();
  }, []);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formValues.title) newErrors.title = 'Title is required';
    if (!formValues.description) newErrors.description = 'Description is required';
    if (!formValues.type) newErrors.type = 'Type is required';
    if (!formValues.originalLanguage) newErrors.originalLanguage = 'Original language is required';
    if (!formValues.acknowledgement) newErrors.acknowledgement = 'Acknowledgement is required';
    if (!formValues.file) newErrors.file = 'File is required';
    if (!formValues.thumbnail) newErrors.thumbnail = 'Thumbnail is required';
    if (!formValues.categoryID) newErrors.categoryID = 'Category is required';
    if (!formValues.channelID) newErrors.channelID = 'Channel is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEbookData = (formData: FormData): string[] => {
    const errors: string[] = [];
    
    // Required fields
    const requiredFields = ['title', 'description', 'type', 'originalLanguage', 'channelID', 'categoryID'];
    
    for (const field of requiredFields) {
      if (!formData.get(field)) {
        errors.push(`${field} is required`);
      }
    }
    
    // File validation
    const file = formData.get('file') as File | null;
    const thumbnail = formData.get('thumbnail') as File | null;
    
    if (!file) {
      errors.push('Ebook file is required');
    } else if (file instanceof File && !file.type.includes('pdf')) {
      errors.push('Ebook file must be a PDF');
    }
    
    if (!thumbnail) {
      errors.push('Thumbnail is required');
    } else if (thumbnail instanceof File && !thumbnail.type.includes('image/')) {
      errors.push('Thumbnail must be an image file');
    }
    
    return errors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, type } = e.target;
    if (type === 'file') {
      const files = (e.target as HTMLInputElement).files;
      setFormValues((prev) => ({
        ...prev,
        [name]: files ? files[0] : null,
      }));
    } else if (type === 'checkbox') {
      setFormValues((prev) => ({
        ...prev,
        [name]: (e.target as HTMLInputElement).checked,
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: e.target.value,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSuccessMessage(null);
    setErrorMessage(null);
  
    if (!validateForm()) return;
  
    const formData = new FormData();
    
    // Debug current values
    console.log('Form Values:', formValues);
    
    // Map frontend fields to backend expected fields
    const fieldMappings = {
      title: 'title',
      description: 'description',
      type: 'type',
      acknowledgement: 'acknowledgement',
      originalLanguage: 'original_language',
      categoryID: 'categoryID',
      channelID: 'channelID',
      file: 'file',
      thumbnail: 'thumbnail',
      drmProtected: 'drm_protected'
    };
  
    // Add each field to FormData with proper mapping
    Object.entries(formValues).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        const backendField = fieldMappings[key as keyof typeof fieldMappings];
        
        if (value instanceof File) {
          formData.append(backendField, value);
        } else if (typeof value === 'boolean') {
          formData.append(backendField, value ? 'true' : 'false');
        } else {
          formData.append(backendField, String(value));
        }
      }
    });
  
    try {
      // Debug the FormData using Array.from
      console.log('=== FormData Contents ===');
      Array.from(formData.entries()).forEach(([key, value]) => {
        console.log(key, value);
      });
  
      const response = await addEbook(formData);
      console.log('Success Response:', response);
      setSuccessMessage('Ebook added successfully!');
      
      // Reset form
      setFormValues({
        title: '',
        description: '',
        type: '4',
        acknowledgement: '',
        originalLanguage: '',
        file: null,
        drmProtected: false,
        thumbnail: null,
        channelID: '',
        categoryID: ''
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Server Error Response:', error.response.data);
        const errorMessage = typeof error.response.data === 'object' 
          ? JSON.stringify(error.response.data) 
          : String(error.response.data);
        setErrorMessage(`Failed to add ebook: ${errorMessage}`);
      } else {
        setErrorMessage('Failed to add ebook: Unknown error occurred');
      }
      console.error('Form submission error:', error);
    }
  };
  return (
    <div className="max-w-4xl   mx-auto p-10 bg-white-50 rounded-xl shadow-xl">
      <h2 className="text-3xl font-bold mb-6 text-center text-blue-900">Add New Ebook</h2>

      {successMessage && <div className="text-black-600 text-center p-3 rounded-lg mb-4 bg-green-100">{successMessage}</div>}
      {errorMessage && <div className="text-red-600 text-center p-3 rounded-lg mb-4 bg-red-100">{errorMessage}</div>}

      <form onSubmit={handleSubmit} encType="multipart/form-data" className="space-y-6">
        <div className="space-y-2">
          <label htmlFor="title" className="text-sm font-semibold text-gray-900 block">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formValues.title}
            onChange={handleChange}
            className={`w-full border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 transition duration-150 ease-in-out`}
          />
          {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
        </div>

        <div className="space-y-2">
          <label htmlFor="description" className="text-sm font-semibold text-gray-900 block">Description</label>
          <textarea
            id="description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            className={`w-full border ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 transition duration-150 ease-in-out`}
          />
          {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
        </div>

        <div className="space-y-2">
          <label htmlFor="type" className="text-sm font-semibold text-gray-900 block">Type</label>
          <select
            id="type"
            name="type"
            value={formValues.type}
            onChange={handleChange}
            className={`w-full border ${errors.type ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 transition duration-150 ease-in-out`}
          >
            {TYPE_CHOICES.map((choice) => (
              <option key={choice.value} value={choice.value}>
                {choice.label}
              </option>
            ))}
          </select>
          {errors.type && <p className="text-red-500 text-sm mt-1">{errors.type}</p>}
        </div>

        <div className="space-y-2">
          <label htmlFor="acknowledgement" className="text-sm font-semibold text-gray-900 block">Acknowledgement</label>
          <input
            type="text"
            id="acknowledgement"
            name="acknowledgement"
            value={formValues.acknowledgement}
            onChange={handleChange}
            className={`w-full border ${errors.acknowledgement ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 transition duration-150 ease-in-out`}
          />
          {errors.acknowledgement && <p className="text-red-500 text-sm mt-1">{errors.acknowledgement}</p>}
        </div>

        <div className="space-y-2">
          <label htmlFor="originalLanguage" className="text-sm font-semibold text-gray-900 block">Original Language</label>
          <input
            type="text"
            id="originalLanguage"
            name="originalLanguage"
            value={formValues.originalLanguage}
            onChange={handleChange}
            className={`w-full border ${errors.originalLanguage ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 transition duration-150 ease-in-out`}
          />
          {errors.originalLanguage && <p className="text-red-500 text-sm mt-1">{errors.originalLanguage}</p>}
        </div>

        <div className="space-y-2">
          <label htmlFor="file" className="text-sm font-semibold text-gray-900 block">Upload PDF</label>
          <input
            type="file"
            id="file"
            name="file"
            onChange={handleChange}
            className="w-full text-sm text-gray-600 file:border-none file:rounded-lg file:bg-indigo-500 file:text-white file:px-4 file:py-2 file:hover:bg-blue-600 file:transition file:duration-150 ease-in-out"
          />
          {errors.file && <p className="text-red-500 text-sm mt-1">{errors.file}</p>}
        </div>

        <div className="space-y-2">
          <label htmlFor="thumbnail" className="text-sm font-semibold text-gray-900 block">Upload Thumbnail</label>
          < input
            type="file"
            id="thumbnail"
            name="thumbnail"
            onChange={handleChange}
            className="w-full text-sm text-gray-600 file:border-none file:rounded-lg file:bg-indigo-500 file:text-white file:px-4 file:py-2 file:hover:bg-blue-600 file:transition file:duration-150 ease-in-out"
          />
          {errors.thumbnail && <p className="text-red-500 text-sm mt-1">{errors.thumbnail}</p>}
        </div>

        <div className="space-y-2">
          <label htmlFor="categoryID" className="text-sm font-semibold text-gray-900 block">Category</label>
          <select
            id="categoryID"
            name="categoryID"
            value={formValues.categoryID}
            onChange={handleChange}
            className={`w-full border ${errors.categoryID ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 transition duration-150 ease-in-out`}
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          {errors.categoryID && <p className="text-red-500 text-sm mt-1">{errors.categoryID}</p>}
        </div>

        <div className="space-y-2">
          <label htmlFor="channelID" className="text-sm font-semibold text-gray-900 block">Channel</label>
          <select
            id="channelID"
            name="channelID"
            value={formValues.channelID}
            onChange={handleChange}
            className={`w-full border ${errors.channelID ? 'border-red-500' : 'border-gray-300'} rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 p-3 transition duration-150 ease-in-out`}
          >
            <option value="">Select a channel</option>
            {channels.map((channel) => (
              <option key={channel.id} value={channel.id}>
                {channel.name}
              </option>
            ))}
          </select>
          {errors.channelID && <p className="text-red-500 text-sm mt-1">{errors.channelID}</p>}
        </div>

        <div className="space-y-2 flex items-center">
          <label htmlFor="drmProtected" className="text-sm font-semibold text-gray-900 block">DRM Protected</label>
          <input
            type="checkbox"
            id="drmProtected"
            name="drmProtected"
            checked={formValues.drmProtected}
            onChange={handleChange}
            className="ml-2 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
          />
        </div>

        <div>
          <button
            type="submit"
            className="w-full bg-blue-900 text-white py-3 px-6 rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 transition duration-300"
          >
            Add Ebook
          </button>
        </div>
      </form>

      {/* Modal for Success Message */}
      {successMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold text-blue-600 mb-4">Success</h2>
            <p>{successMessage}</p>
            <button
              onClick={() => setSuccessMessage(null)}
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddEbookForm;
