import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Views from "./Views";
import Likes from "./Likes";
import Share from "./Share";
import PersonDisplayer from "./PersonDisplayer";
import ReactPlayer, { ReactPlayerProps } from "react-player";
import { Channel, Person, PlayerProps, Video } from "../types/domain";

import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";

import { red } from "@mui/material/colors";
import { FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  addView,
  fetchInterviewees,
  fetchInterviewers,
} from "../services/generalVideoServices";

import { isRTL } from "../services/script";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface VideoSectionProps {
  videoData: Video;
  playerRef: any;
  channelData: Channel;
}

export default function VideoSection({
  videoData,
  playerRef,
  channelData,
}: VideoSectionProps) {
  const [playerProps, setPlayerProps] = useState<ReactPlayerProps>(
    {} as ReactPlayerProps
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [playing, setPlaying] = useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const [interviewees, setInterviewees] = useState<any>(null);
  const [interviewers, setInterviewers] = useState<any>(null);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const navigate = useNavigate();

  useEffect(() => {
    setIsLiked(videoData?.is_liked_by_user);
    addView(videoData.mediaID);
    const fetchData = async () => {
      setInterviewees(await fetchInterviewees(videoData.mediaID));
      setInterviewers(await fetchInterviewers(videoData.mediaID));
    };

    fetchData();
  }, []);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        padding: 1,
        height: "100%",
      }}
    >
      <Typography
        variant="h1"
        sx={{ marginBottom: 2 }}
        dir={isRTL(videoData?.media_details.title) ? "rtl" : "ltr"}
      >
        {videoData?.media_details.title}
      </Typography>
      <ReactPlayer
        ref={playerRef}
        url={videoData?.videoURL}
        controls
        width="100%"
        height={720}
        playing={playing}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        fallback={<div>Loading...</div>}
      />

      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: red[500] }}
            aria-label="recipe"
            src="broken"
            alt={channelData?.name}
            onClick={() => {
              navigate(`/videos/channel/${channelData.id}`);
            }}
          />
        }
        action={
          <div className="flex">
            <Likes
              likesToPass={videoData?.media_details.likes}
              mediaID={videoData.mediaID}
              is_liked_by_user={videoData.is_liked_by_user}
            />

            <Share url={window.location.href} />
          </div>
        }
        title={channelData?.name}
        subheader={
          <div className="flex gap-1">
            {videoData?.media_details.uploadDate}
            <Views viewsToPass={videoData?.media_details.views} />
          </div>
        }
      />
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Collapse in={expanded} timeout="auto" collapsedSize={40}>
          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
              dir={isRTL(videoData?.media_details.description) ? "rtl" : "ltr"}
            >
              {videoData?.media_details.description}
            </Typography>

            <div>
              {interviewees?.length !== 0 && (
                <>
                  <Typography variant="h6">Interviewees</Typography>
                  <List
                    sx={{
                      width: "fit-content",
                      bgcolor: "background.paper",
                    }}
                  >
                    {interviewees?.map((person: Person, index: number) => (
                      <div key={index}>
                        <PersonDisplayer person={person} />
                        <Divider variant="inset" component="li" />
                      </div>
                    ))}
                  </List>
                </>
              )}
              {interviewers?.length !== 0 && (
                <>
                  <Typography variant="h6">Interviewers</Typography>
                  <List
                    sx={{
                      width: "fit-content",
                      bgcolor: "background.paper",
                    }}
                  >
                    {interviewers?.map((person: Person, index: number) => (
                      <div key={index}>
                        <PersonDisplayer person={person} />
                        <Divider variant="inset" component="li" />
                      </div>
                    ))}
                  </List>
                </>
              )}
            </div>
          </CardContent>
        </Collapse>
        <CardActions disableSpacing>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            sx={{ alignSelf: "flex-end" }}
          >
            <FaChevronDown />
          </ExpandMore>
        </CardActions>
      </CardContent>
    </Card>
  );
}
