import React from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '../../layouts/GeneralAppBar/AppBar';
import Footer from '../../components/GeneralAppBar/footer';
import VideoGuidelineRoadmap from '../../components/GeneralAppBar/VideoGuidelineRoadmap';

const Guideline = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col justify-between relative">
      {/* Background Image with Gradient Overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-fixed"
        style={{ backgroundImage: "url('/assets/ifrane.png')", zIndex: -1 }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-white/95 via-white/80 to-transparent"></div>
      </div>

      {/* AppBar Component */}
      <AppBar toggleSidebar={() => {}} setSearchQuery={() => {}} searchQuery="" />

      {/* Main Content */}
      <div className="relative flex-grow container mx-auto px-4 py-16">
        <div className="max-w-6xl mx-auto">
          {/* Page Title */}
          <div className="text-center mb-16 mt-20">
            <div className="flex items-center justify-center mb-6">
              <div className="w-16 h-0.5 bg-green-800"></div>
              <div className="w-3 h-3 rounded-full bg-green-800 mx-4"></div>
              <div className="w-16 h-0.5 bg-green-800"></div>
            </div>
            <h1 className="text-6xl font-bold text-black mb-6">{t('guide.title')}</h1>
            <div className="flex items-center justify-center">
              <div className="w-24 h-0.5 bg-green-800"></div>
              <div className="w-2 h-2 rounded-full bg-green-800 mx-4"></div>
              <div className="w-24 h-0.5 bg-green-800"></div>
            </div>
          </div>

          {/* Roadmap Component */}
          <VideoGuidelineRoadmap />

          {/* Mission Statement Below Roadmap */}
          <div className="backdrop-blur-md bg-white/90 p-8 rounded-2xl shadow-2xl border border-green-100 mt-12">
            <h2 className="text-2xl font-semibold text-black mb-6 flex items-center">
              <span className="w-1.5 h-8 bg-green-800 mr-4 rounded-full"></span>
              {t('guide.formTitle')}
            </h2>
            <div className="prose prose-xl max-w-none text-black-700">
              <p className="text-xl leading-relaxed mt-6">{t('guide.formDescription')}</p>
              <ol className="list-decimal list-inside text-lg">
                <li>{t('guide.formSteps.page1')}</li>
                <li>{t('guide.formSteps.page2')}</li>
                <li>{t('guide.formSteps.page3')}</li>
              </ol>
              <p className="text-xl leading-relaxed mt-6">{t('guide.formConclusion')}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Guideline;
