import { toast } from "react-toastify";
import {
  Channel,
  combinedSegmentTranscript,
  Playlist,
  Transcript,
  Video,
} from "../types/domain";

const BASE_URL = process.env.REACT_APP_Django_API_URL;

// This function fetches video data and returns a Video object.
// Regardless of the source of the video data (YouTube, OAP storage, and other integrated platforms...)
// It is currently a placeholder function that will be replaced with a real implementation.
// For now, it simply returns a hard-coded Video object.
export const fetchVideoDataByID = async (
  id: string | undefined
): Promise<Video | null> => {
  const response = await fetch(`${BASE_URL}/api/v/video/${id}/`);
  if (response.ok) {
    const videoData = await response.json();

    return videoData;
  }

  try {
    const response = await fetch(`${BASE_URL}/api/v/video/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });

    if (response.ok) {
      const videoData = await response.json();

      return videoData;
    }
  } catch (error) {
    console.error("Failed to fetch video data", error);
  }

  return null;
};

export const fetchTranscriptsByID = async (id: number): Promise<any> => {
  const token = localStorage.getItem("authToken");
  const response = await fetch(
    `${BASE_URL}/api/v/combined/get_segment_transcript/${id}/`,
    {
      
    }
  );

  if (response.ok) {
    const Data = await response.json();
    const combinedSegmentTranscripts: combinedSegmentTranscript[] = Data.map(
      (item: any) => {
        return {
          segment: item.segment,
          transcripts: item.transcripts,
        };
      }
    );

    const languages = combinedSegmentTranscripts.map((CST) => {
      return CST.transcripts.map((T) => T.transcriptionLanguage);
    });

    const availbleLanguages = Array.from(new Set(languages.flat())).map(
      (language) => {
        return language;
      }
    );

    return { combinedSegmentTranscripts, availbleLanguages };
  }

  return {
    combinedSegmentTranscripts: [],
    availbleLanguages: [],
  };
};

export const fetchCommentsByID = async (id: number): Promise<any> => {
  const response = await fetch(`${BASE_URL}/api/m/comment/by-video/${id}/`);

  if (response.ok) {
    const Data = await response.json();
    return Data;
  }

  return null;
};

export const addComment = async (mediaID: number, comentString: string) => {
  if (comentString.length < 1) return;

  const newComment = {
    body: comentString,
    mediaID: mediaID,
  };

  const token = localStorage.getItem("authToken");

  fetch(`${BASE_URL}/api/m/comment/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newComment),
  }).catch((error) => {
    alert("Failed to add comment");
  });
};

export const fetchPlaylistDataByID = async (
  id: string | undefined
): Promise<Playlist | null> => {
  return null;
};

export const fetchChannelDataByID = async (
  id: string
): Promise<Channel | null> => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await fetch(`${BASE_URL}/api/c/channel/${id}/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if the response is okay (status in the range 200-299)
    if (!response.ok) {
      return null;
    }

    // Parse and return the JSON data
    return await response.json();
  } catch (error) {
    console.error("Error fetching channel data:", error);
    return null;
  }
};

export const fetchInterviewees = async (id: number) => {
  const token = localStorage.getItem("authToken");
  try {
    const response = await fetch(
      `${BASE_URL}/api/v/participant/retrieve_by_role/?video_id=${id}&role=1`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Check if the response is okay (status in the range 200-299)
    if (!response.ok) {
      
      return null;
    }

    // Parse and return the JSON data
    return await response.json();
  } catch (error) {
    toast.warning("Failed to fetch interviewers");

    console.error("Error fetching channel data:", error);
    return null;
  }
};

export const fetchInterviewers = async (id: number) => {
  const token = localStorage.getItem("authToken");
  try {
    const response = await fetch(
      `${BASE_URL}/api/v/participant/retrieve_by_role/?video_id=${id}&role=2`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Check if the response is okay (status in the range 200-299)
    if (!response.ok) {
      return null;
    }

    // Parse and return the JSON data
    return await response.json();
  } catch (error) {
    toast.warning("Failed to fetch interviewers");
    console.error("Error fetching channel data:", error);
    return null;
  }
};

export const addLike = async (mediaID: number) => {
  const token = localStorage.getItem("authToken");

  fetch(`${BASE_URL}/api/m/like/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ mediaID: mediaID }),
  }).catch((error) => {
    alert("Failed to like");
  });
};

export const addView = async (mediaID: number) => {
  const token = localStorage.getItem("authToken");

  fetch(`${BASE_URL}/api/m/view/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ mediaID: mediaID }),
  }).catch((error) => {
    alert("Failed to add view");
  });
};

export const fetchVideosByChannelID = async (id: string): Promise<Video[]> => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await fetch(
      `${BASE_URL}/api/v/video/get_channel_videos/?channel_id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Check if the response is okay (status in the range 200-299)
    if (!response.ok) {
      return [];
    }

    // Parse and return the JSON data
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error fetching channel data:", error);
    return [];
  }
};

export const fetchPlaylistsByChannelID = async (
  id: string
): Promise<Playlist[]> => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await fetch(
      `${BASE_URL}/api/p/playlist/channel/${id}/collections/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Check if the response is okay (status in the range 200-299)
    if (!response.ok) {
      return [];
    }

    // Parse and return the JSON data
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error fetching channel data:", error);
    alert("Failed to fetch playlists");
    return [];
  }
};
