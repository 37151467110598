import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoadmapStepProps, Step } from '../../services/GeneralAppBar';

const RoadmapStep: React.FC<RoadmapStepProps> = ({ icon, number, title, isLast = false }) => (
  <div className="relative flex items-center">
    <div className="bg-yellow-400 rounded-full w-12 h-12 flex items-center justify-center">
      {icon}
    </div>
    <div className="ml-4">
      <div className="font-bold text-m">{number}.</div>
      <div className="font-bold">{title}</div>
    </div>
  </div>
);

const VideoGuidelineRoadmap: React.FC = () => {
  const { t } = useTranslation();

  const steps: Step[] = [
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
        </svg>
      ),
      title: t('guide.steps.signIn.title'),
      description: t('guide.steps.signIn.description')
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
        </svg>
      ),
      title: t('guide.steps.createChannel.title'),
      description: t('guide.steps.createChannel.description')
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
        </svg>
      ),
      title: t('guide.steps.navigateVideos.title'),
      description: t('guide.steps.navigateVideos.description')
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
        </svg>
      ),
      title: t('guide.steps.uploadVideo.title'),
      description: t('guide.steps.uploadVideo.description')
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
      ),
      title: t('guide.steps.fillDetails.title'),
      description: t('guide.steps.fillDetails.description')
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg p-8 relative">
      <h2 className="text-2xl font-semibold text-black mb-6 flex items-center">
        <span className="w-1.5 h-8 bg-green-800 mr-4 rounded-full"></span>
        {t('guide.title')}
      </h2>
      <div className="relative grid grid-cols-1 md:grid-cols-5 gap-8">
        {steps.map((step, index) => (
          <div key={index} className={`relative ${index % 2 === 0 ? 'md:mt-0' : 'md:mt-16'}`}>
            <RoadmapStep icon={step.icon} number={index + 1} title={step.title} isLast={index === steps.length - 1} />
            <p className="mt-2 text-m text-black-600">{step.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoGuidelineRoadmap;
