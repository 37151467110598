/**
 * Basic layout props for a video page, requiring children.
 */
export interface VideoLayoutProps {
  children: React.ReactNode;
}

/**
 * Props for a sidebar component.
 */
export interface SidebarProps {
  isCollapsed: boolean;
  className?: string;
}

/**
 * Represents a transcript object.
 */
export interface Transcript {
  transcription: string;
  transcriptionLanguage: string;
  videoSegmentID: number;
}

/**
 * Props for an AppBar component, requiring a method to toggle the sidebar.
 */
export interface AppBarProps {
  toggleSidebar: () => void;
}

/**
 * Each segment of the video can have a title, start/end time, description, 
 * and multiple transcripts (in different languages).
 */
export interface Segment {
  title: string;
  startTime: string;
  endTime: string;
  description: string; 
  transcripts: {
    language: string;
    transcript: string;
  }[];
}

/**
 * A person interface, used for interviewees/interviewers/participants.
 */
export interface Person {
  firstName: string;
  lastName: string;
  picture?: string;
  phoneNumber?: string;
  email?: string;
  role: number; 
}

/**
 * Main Video interface describing all the fields for a video entity.
 */
export interface Video {
  id: number; // Unique identifier for the video
  transcripts: Transcript[]; // Single transcript object (though you might handle multiple transcripts if needed)
  title: string; 
  description: string; 
  category: string; 
  collectionName: string; 
  customCategory?: string; 
  customCollectionName?: string; 
  url: string; 
  mediaFormat: string; 
  originalLanguage: string; 
  segments: Segment[]; 
  interviewDate: string; 
  location: {
    city_name?: string; 
    latitude?: number;
    longitude?: number;
  };
  acknowledgment: string; 
  interviewees: Person[]; 
  interviewers: Person[]; 
  views: number; 
  comments: number; 
  likes: number; 
  visibility: string; 
  restriction: string; 
  fullTranscript: string; 
  media_details: {
    title: string; 
    uploaderID: number; 
    description: string; 
    uploadDate: string; 
    type: string; 
    originalLanguage: string; 
    categoryID: { name: string }; 
    channelID: number; 
  };
  channelId?: number; 
  videoURL: string; 
  size: number; 
  participants: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    role: number; 
  }[]; 
  topics: string[]; 
  important_persons: string[]; 
}

/**
 * Initial state for the form data, matching the Video interface.
 */
export const initialFormData: Video = {
  id: 0,
  title: '',
  description: '',
  category: '',
  collectionName: '',
  customCategory: '',
  customCollectionName: '',
  fullTranscript: '', // Full transcript field
  url: '',
  mediaFormat: 'Video',
  originalLanguage: '',
  transcripts: [],
  segments: [],
  interviewDate: '',
  location: {
    city_name: '',
    latitude: 0,
    longitude: 0,
  },
  acknowledgment: '',
  interviewees: [],
  interviewers: [],
  views: 0,
  comments: 0,
  likes: 0,
  visibility: 'public', 
  restriction: '1',
  media_details: {
    title: '',
    uploaderID: 1,
    description: '',
    uploadDate: '',
    type: '1',
    originalLanguage: '',
    categoryID: { name: '' },
    channelID: 0,
  },
  videoURL: '',
  size: 0,
  participants: [],
  topics: [],
  important_persons: [],
};

/**
 * A form context for managing Video data in a higher-level context.
 */
export interface FormContextProps {
  formData: Video;
  updateFormData: (newData: Partial<Video>) => void;
  resetFormData: () => void;
}

/**
 * Props for a SegmentModal component (used to add/edit segments).
 */
export interface SegmentModalProps {
  onClose: () => void;
  onSave: (segment: Segment) => void;
  segment: Segment | null;
  segments: Segment[]; 
}

/**
 * A Segment type that includes an optional index for editing in a list.
 */
export interface SegmentWithIndex extends Segment {
  index?: number;
}

/**
 * Props for a VideoList component that displays multiple videos.
 */
export interface VideoListProps {
  videos: Video[];
  onDelete: (id: number) => void;
}

/**
 * Props for a VideoForm component that manages adding/updating videos.
 */
export interface VideoFormProps {
  videos: Video[];
  onAdd: (video: Video) => void;
  onUpdate: (video: Video) => void;
}

/**
 * Props for the Modal component that handles adding/editing people 
 * (interviewees, interviewers, or participants).
 */
export interface ModalProps {
  type: 'interviewee' | 'interviewer';
  onClose: () => void;
  onSave: (
    firstName: string, 
    lastName: string, 
    picture?: File, 
    phoneNumber?: string, 
    email?: string
  ) => void;
  initialData?: Person | null; 
}

/**
 * Props for a progress indicator or stepper component.
 */
export interface ProgressIndicatorProps {
  currentStep: number;
  onStepChange: (step: number) => void;
  canMoveToStep: (step: number) => boolean;
}

/**
 * Props for a details form step, typically collecting basic info about a video.
 */
export interface DetailsProps {
  formData: {
    title: string;
    media_details: {
      title: string; 
      uploaderID: number; 
      description: string; 
      uploadDate: string; 
      type: string; 
      originalLanguage: string; 
      categoryID: { name: string }; 
      channelID: number; 
    };
    description: string;
    category: string;
    collectionName: string;
    customCategory?: string;
    customCollectionName?: string;
    visibility: string;
    restriction: string;
    originalLanguage: string; 
    videoURL: string;
  };
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  handleNext: () => void;
}

/**
 * Props for a video elements form step, typically collecting advanced info.
 */
export interface VideoElementsProps {
  formData: Video;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  handleNext: () => void;
  handleBack: () => void;
  updateFormData: (newData: Partial<VideoElementsProps['formData']>) => void;
}

/**
 * Props for a checks step (e.g., disclaimers, transcript, location).
 */
export interface checkProps {
  formData: {
    interviewDate: string;
    location: { 
      city_name?: string; 
      latitude?: number;
      longitude?: number;
    };
    acknowledgment: string;
    interviewees: Person[];
    interviewers: Person[];
    participants: Person[]; 
    topics: string[];
    important_persons: string[];
    transcripts: Transcript[]; 
    fullTranscript: string;
  };
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  handleBack: () => void;
  handleSubmit: (e: React.FormEvent) => void;
  handleSavePerson: (
    type: 'interviewee' | 'interviewer' | 'participant',
    firstName: string,
    lastName: string,
    picture?: File,
    phoneNumber?: string,
    email?: string,
    index?: number
  ) => void;
}

/**
 * Props for a pagination component.
 */
export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
  totalItems: number;
  setItemsPerPage: (value: number) => void;
}

/**
 * A simple filter state interface for videos (by type, views, category, etc.).
 */
export interface FilterState {
  type: string;
  minViews: number;
  maxViews: number;
  category: string;
}

/**
 * Props for a quantity input component (like a numeric stepper).
 */
export interface QuantityInputProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
}

/**
 * Props for a video filter component that updates a FilterState.
 */
export interface VideoFilterProps {
  filters: FilterState;
  onFilterChange: (key: string, value: any) => void;
}
